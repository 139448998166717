import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import promise from 'redux-promise';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react';
// import { autoMergeLevel2 } from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import rootReducer  from './rootReducer'
import  secureLocalStorage  from  "react-secure-storage";


const persistConfig = {
  key: 'root',
  storage: secureLocalStorage,
  whitelist: ['userInfo', 'users', 'user'],
  stateReconciler: autoMergeLevel2, 
};

persistConfig.debug = true;

const persistedReducer = persistReducer(persistConfig, rootReducer);





// RETREIVING USER FROM STORAGE
const userInfoFromStorage = secureLocalStorage.getItem('userInfo')
	? JSON.parse(secureLocalStorage.getItem('userInfo'))
	: null


//INITIAL STATE
const initialState = {
	userLogin: {
		userInfo: userInfoFromStorage,
	},

}

// const initialState = {
//     userLogin: {},
// };

const middleware = [thunk]

// sanitizers to keep redux devtools from using excessive memory
// const actionSanitizer = action =>
//    action.type === 'ALL_VIDEO_LIST_REQUEST' && action.data 
//     ? { ...action, data: '<<LONG_BLOB>>' }
//     : action

// const composeEnhancers = composeWithDevTools({
//   actionSanitizer,
//   stateSanitizer: (state) => state.data ? { ...state, data: '<<LONG_BLOB>>' } : state
// });

const store = createStore(
	persistedReducer, 
	initialState, 
	composeWithDevTools(
		applyMiddleware(...middleware, promise)

	)
	)


	// const store = createStore(
	// persistedReducer, 
	// initialState, 
	// composeEnhancers(
	// 	applyMiddleware(...middleware, promise)
	// )
	// )

export default store

// setupMiddlewares