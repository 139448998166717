import Retool from 'react-retool';
// ES6 Imports
// import React, { useEffect, Component } from 'react'
// import * as Scroll from 'react-scroll';
// import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const Analytics = () => {
//   const sample = { name: 'Sample data' }
  return (
    <div style={{height: 1000}}>
            <Retool 
      url="https://golgiisentiment.retool.com/embedded/public/6b586f92-11f3-4290-8df5-08e370f2b954"
    //   data={sample}
    />
    </div>
    
  );
}

export default Analytics;




// // Or Access Link,Element,etc as follows
// let Link      = Scroll.Link;
// let Button    = Scroll.Button;
// let Element   = Scroll.Element;
// let Events    = Scroll.Events;
// let scroll    = Scroll.animateScroll;
// let scrollSpy = Scroll.scrollSpy;

// // ES5
// var React  = require('react');
// var Scroll = require('react-scroll');

// var Link      = Scroll.Link;
// var Button    = Scroll.Button;
// var Element   = Scroll.Element;
// var Events    = Scroll.Events;
// var scroll    = Scroll.animateScroll;
// var scrollSpy = Scroll.scrollSpy;





// class  Analytics extends Component { 

//   componentDidMount() {
//     Events.scrollEvent.register('begin', function(to, element) {
//       console.log('begin', arguments);
//     });

//     Events.scrollEvent.register('end', function(to, element) {
//       console.log('end', arguments);
//     });

//     scrollSpy.update();
//   }

//   componentWillUnmount() {
//     Events.scrollEvent.remove('begin');
//     Events.scrollEvent.remove('end');
//   } 
//   scrollToTopn() {
//     scroll.scrollToTop();
//   }
//   scrollToBottom() {
//     scroll.scrollToBottom();
//   }
//   scrollTo() {
//     scroll.scrollTo(100);
//   }
//   scrollMore() {
//     scroll.scrollMore(100);
//   }
//   handleSetActive(to) {
//     console.log(to);
//   }

//   render () {
//     return (
//       <div>
//         <Link activeClass="active" to="test1" spy={true} smooth={true} offset={50} duration={500} onSetActive={this.handleSetActive}>
//           Test 1
//         </Link>
//         <Link activeClass="active" to="test1" spy={true} smooth={true} offset={50} duration={500} delay={1000}>
//           Test 2 (delay)
//         </Link>
//         <Link className="test6" to="anchor" spy={true} smooth={true} duration={500}>
//           Test 6 (anchor)
//         </Link>
//         <Button activeClass="active" className="btn" type="submit" value="Test 2" to="test2" spy={true} smooth={true} offset={50} duration={500} >
//           Test 2
//         </Button>

//         <Element name="test1" className="element">
//           test 1
//         </Element>

//         <Element name="test2" className="element">
//           test 2
//         </Element>

//         <div id="anchor" className="element">
//           test 6 (anchor)
//         </div>

//         <Link to="firstInsideContainer" containerId="containerElement">
//           Go to first element inside container
//         </Link>

//         <Link to="secondInsideContainer" containerId="containerElement">
//           Go to second element inside container
//         </Link>
//         <div className="element" id="containerElement">
//           <Element name="firstInsideContainer">
//             first element inside container
//           </Element>

//           <Element name="secondInsideContainer">
//             second element inside container
//           </Element>
//         </div>

//         <a href='/' onClick={this.scrollToTop}>To the top!</a>
//         <br/>
//         <a href='/' onClick={this.scrollToBottom}>To the bottom!</a>
//         <br/>
//         <a href='/' onClick={this.scrollTo}>Scroll to 100px from the top</a>
//         <br/>
//         <a href='/' onClick={this.scrollMore}>Scroll 100px more from the current position!</a>
//       </div>
//     );
//   }
// }

// export default Analytics;