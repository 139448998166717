import React, {useEffect} from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Outlet, 
  Link
} from "react-router-dom";
import { Container, Card } from 'react-bootstrap'
import Header from './components/Header'
import Footer from './components/Footer'
import Analytics from './screens/Analytics';
import ImageCarousel from './screens/ImageCarousel'
import CompanyRatedVideoList from './screens/CompanyRatedVideoList';
import backGroundImage from "./screens/asset/background_image.jpeg";
import PrivacyPolicy from './screens/PrivacyPolicy';
import ServiceTerms from './screens/ServiceTerms';
import {useMediaQuery, useMediaQueries} from '@react-hook/media-query'
import { GlobalDebug } from './utils/removeConsole';
import ReactGA from 'react-ga';
import SpinnerIcon from './components/Spinner';
import { isExpired, decodeToken } from "react-jwt";
import { useNavigate } from "react-router-dom"
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import AboutUs from './screens/AboutUs';




const LandingPage = React.lazy(() => import('./screens/LandingPage'))
const LoginScreen = React.lazy(() => import('./screens/LoginScreen'))
const RegisterScreen = React.lazy(() => import('./screens/RegisterScreen'))
const CommentsRatingSearch = React.lazy(() => import('./screens/CommentsRatingSearch'))
const ProfileScreen = React.lazy(() => import('./screens/ProfileScreen'))
const VideoCarousel = React.lazy(() => import('./screens/VideoCarousel'))
const DisplayAllVideoScreen = React.lazy(() => import('./screens/DisplayAllVideoScreen'))
const MarkedVideos = React.lazy(() => import('./screens/MarkedVideos'))
const MyVideoListScreen = React.lazy(() => import('./screens/MyVideoListScreen'))
const VideoMyCarousel = React.lazy(() => import('./screens/VideoMyCarousel'))
const MarkedVideosCarousel = React.lazy(() => import('./screens/MarkedVideosCarousel'))
const VideoCreateScreen = React.lazy(() => import('./screens/VideoCreateScreen'))
const BarCharts = React.lazy(() => import('./screens/Analytics/BarCharts'))
const BlockedUsersList = React.lazy(() => import('./screens/BlockedUsersList'))
const PasswordReset = React.lazy(() => import('./screens/PasswordReset'))
const ForgotPassword = React.lazy(() => import('./screens/ForgotPassword'))
const Contact = React.lazy(() => import('./screens/Contact'))
const NotFound = React.lazy(() => import('./components/NotFound'))












  const MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENT_ID; // OUR_MEASUREMENT_ID
  ReactGA.initialize(MEASUREMENT_ID);

export const URL = process.env.REACT_APP_SERVER_URL






const BasicLayout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  )
}

const AdminLayout = () => {
  return  (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  )
    
}



const App = () => {




      useEffect(() => {
        (process.env.NODE_ENV === "production" ||
        process.env.REACT_APP_ENV === "STAGING") &&
          GlobalDebug(false);
      }, []);


  if (process.env.NODE_ENV === "production")
    console.log = function no_console() {};


  return (
   
    <Router className='videoDisplay' sm={12} xs={12} md={12} lg={12} xl={12} xxl={12} 
      style={{
        margin: 0, 
        padding: 0,
        paddingRight: '0!important',
        paddingLeft: '0!important',
        overflowX: 'hidden',
        width: '100%', 
      }}

      >
      <main  className='videoDisplay' sm={12} xs={12} md={12} lg={12} xl={12} xxl={12}
        style={{ 
            
              // backgroundImage: `url("./screens/asset/background_image.jpeg")`,
              // backgroundImage: `url(${backGroundImage})`,  
              // backgroundPosition: 'center',
              // backgroundSize: 'cover',         
              // backgroundRepeat: 'no-repeat', 
              // backgroundColor: 'FAFCFC',
              // backgroundColor: '#faf8f5',
              backgroundColor: '#ffffff',
              margin: 0,
              padding: 0,
              // justifyContent: 'center', 
              width: '100%', 
              height:"auto",
              overflowX: 'hidden'
          
            }}
        >
      {/* <main  style={{backgroundColor: '#232F3E', width: '100%', display: 'flex', flexDirection: 'row', paddingLeft: 4, paddingRight: 4}}> */}
        {/* <Container style={{backgroundColor: 'green', float:'left', width: '20%', minHeight: '87wh'}}><Card style={{backgroundColor: 'green', float:'left', width: '20%', minHeight: '87wh'}}><Card.Body style={{backgroundColor: 'green', float:'left', width: '20%', minHeight: '87wh'}}></Card.Body><h1>hshshsh</h1><h1>hshshsh</h1><h1>hshshsh</h1></Card></Container> */}
         {/* <div style={{backgroundColor: '#f0f0f5', width: '50%'}}> */}
        {/* <Container style={{backgroundColor: '#232F3E'}}> */} 
        {/* <div style={{backgroundColor: '#f0f0f5', width: '60%'}}> */}
          {/* <Container style={{backgroundColor: '#f0f0f5',  }}> */}
           <Container 
            style={{
              // backgroundColor: '#faf8f5'  
               backgroundColor: '#ffffff',
                width: '100%', 
                 overflowX: 'hidden'

              //   marginLeft: 0,  
              //  margin: 0,  
               
            }}
            >
        
          <Routes>
              <Route path='/' element={<BasicLayout />}>
              <Route index element={<React.Suspense fallback={<><SpinnerIcon /></>}><LandingPage /></React.Suspense>} /> 
              <Route path='/login' element={<React.Suspense fallback={<><SpinnerIcon /></>}><LoginScreen /></React.Suspense>} />
              <Route path='/register' element={<React.Suspense fallback={<><SpinnerIcon /></>}><RegisterScreen /></React.Suspense>} />
      
              {/* <Route path="*" element={<NotFound />} /> */}
              <Route path="*" element={<React.Suspense fallback={<><SpinnerIcon /></>}><NotFound /></React.Suspense>} /> 
               <Route path="/aboutUs" element={<AboutUs />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<ServiceTerms />} />
              <Route path='/password-reset' element={<React.Suspense fallback={<><SpinnerIcon /></>}><PasswordReset /></React.Suspense>} /> 
              <Route path='/forgotpassword' element={<React.Suspense fallback={<><SpinnerIcon /></>}><ForgotPassword /></React.Suspense>} /> 
               
          
              
            </Route>
              <Route path="/admin" element={<AdminLayout />}>

              {/* <Route path="*" element={<NotFound />} /> */}

              
              <Route path="*" element={<React.Suspense fallback={<><SpinnerIcon /></>}><NotFound /></React.Suspense>} /> 

              <Route path='/admin/analytics' element={<Analytics />} /> 

              <Route path='/admin/BlockedUsersList' element={<React.Suspense fallback={<><SpinnerIcon /></>}><BlockedUsersList /></React.Suspense>} />  

              <Route path='/admin/videoList' element={<React.Suspense fallback={<><SpinnerIcon /></>}><MyVideoListScreen /></React.Suspense>} /> 
              <Route path='/admin/videoList/search'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><MyVideoListScreen /></React.Suspense>} />
              <Route path='/admin/videoList/search/:keyword'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><MyVideoListScreen /></React.Suspense>} />
              <Route path='/admin/videoList/page/:pageNumber'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><MyVideoListScreen /></React.Suspense>} /> 
              <Route path='/admin/videoList/search/:keyword/page/:pageNumber'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><MyVideoListScreen /></React.Suspense>} />

              <Route path='/admin/companyVideoList' element={<CompanyRatedVideoList />} /> 
              <Route path='/admin/companyVideoList/search/:keyword'  element={<CompanyRatedVideoList />} />
              <Route path='/admin/companyVideoList/page/:pageNumber'  element={<CompanyRatedVideoList />} /> 
              <Route path='/admin/companyVideoList/search/:keyword/page/:pageNumber'  element={<CompanyRatedVideoList />} />

              <Route path='/admin/profileScreen' element={<React.Suspense fallback={<><SpinnerIcon /></>}><ProfileScreen /></React.Suspense>} />  
              <Route path='/admin/profileScreen/:videoCreatorId' element={<React.Suspense fallback={<><SpinnerIcon /></>}><ProfileScreen /></React.Suspense>} />  
              <Route path='/admin/profileScreen/search/:keyword'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><ProfileScreen /></React.Suspense>} />
              {/* <Route path='/admin/profileScreen/search/:videoCreatorId/:keyword'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><ProfileScreen /></React.Suspense>} /> */}
               <Route path='/admin/profileScreen/search'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><ProfileScreen /></React.Suspense>} />
              <Route path='/admin/profileScreen/page/:pageNumber'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><ProfileScreen /></React.Suspense>} />
              <Route path='/admin/profileScreen/search/:videoCreatorId/:keyword/page/:pageNumber'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><ProfileScreen /></React.Suspense>} />
              <Route path='/admin/profileScreen/search/:keyword/page/:pageNumber'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><ProfileScreen /></React.Suspense>} />

               <Route path='/admin/CommentsRatingSearch' element={<React.Suspense fallback={<><SpinnerIcon /></>}><CommentsRatingSearch /></React.Suspense>} /> 

              <Route path='/admin/displayAllVideoScreen' element={<React.Suspense fallback={<><SpinnerIcon /></>}><DisplayAllVideoScreen /></React.Suspense>} /> 
              {/* <Route path='/admin/displayAllVideoScreen/search/:keyword'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><DisplayAllVideoScreen /></React.Suspense>} /> */}
               <Route path='/admin/displayAllVideoScreen/search'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><DisplayAllVideoScreen /></React.Suspense>} />
              <Route path='/admin/displayAllVideoScreen/search/carouselAll/:keyword'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><DisplayAllVideoScreen /></React.Suspense>} />
              <Route path='/admin/displayAllVideoScreen/page/:pageNumber'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><DisplayAllVideoScreen /></React.Suspense>} />
              <Route path='/admin/displayAllVideoScreen/search/:keyword/page/:pageNumber'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><DisplayAllVideoScreen /></React.Suspense>} />


               {/* <Route element={<DisplayImageScreen />}/>

              <Route path='/admin/displayImageScreen' element={<DisplayImageScreen />} /> 
              <Route path='/admin/displayImageScreen/search/:keyword'  element={<DisplayImageScreen />} />
              <Route path='/admin/displayImageScreen/page/:pageNumber'  element={<DisplayImageScreen />} />
              <Route path='/admin/displayImageScreen/search/:keyword/page/:pageNumber'  element={<DisplayImageScreen />} /> */}

               <Route path='/admin/markedVideos' element={<React.Suspense fallback={<><SpinnerIcon /></>}><MarkedVideos /></React.Suspense>} />
              <Route path='/admin/markedVideos/search'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><MarkedVideos /></React.Suspense>} />
              <Route path='/admin/markedVideos/page/:pageNumber'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><MarkedVideos /></React.Suspense>} />
              <Route path='/admin/markedVideos/search/:keywordcom/page/:pageNumber'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><MarkedVideos /></React.Suspense>} />     

              <Route path='/admin/imageCarousel' element={<ImageCarousel />} />  

              <Route path='/admin/videoMyCarousel' element={<React.Suspense fallback={<><SpinnerIcon /></>}><VideoMyCarousel /></React.Suspense>} />  
              <Route path='/admin/videoMyCarousel/search'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><VideoMyCarousel /></React.Suspense>} />
              <Route path='/admin/videoMyCarousel/search/:videoCreatorId/:keyword'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><VideoMyCarousel /></React.Suspense>} />
              <Route path='/admin/videoMyCarousel/page/:pageNumber'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><VideoMyCarousel /></React.Suspense>} />
              <Route path='/admin/videoMyCarousel/search/:videoCreatorId/:keyword/page/:pageNumber'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><VideoMyCarousel /></React.Suspense>} />
              <Route path='/admin/videoMyCarousel/search/:keyword/page/:pageNumber'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><VideoMyCarousel /></React.Suspense>} />

              <Route path='/admin/markedVideoCarousel' element={<React.Suspense fallback={<><SpinnerIcon /></>}><MarkedVideosCarousel /></React.Suspense>} />  
               <Route path='/admin/markedVideoCarousel/search'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><MarkedVideosCarousel /></React.Suspense>} />
              <Route path='/admin/markedVideoCarousel/search/:keyword'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><MarkedVideosCarousel /></React.Suspense>} />
              <Route path='/admin/markedVideoCarousel/page/:pageNumber'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><MarkedVideosCarousel /></React.Suspense>} />
              <Route path='/admin/markedVideoCarousel/search/:keyword/page/:pageNumber'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><MarkedVideosCarousel /></React.Suspense>} />

              <Route path='/admin/videoCarousel' element={<React.Suspense fallback={<><SpinnerIcon /></>}><VideoCarousel /></React.Suspense>} />  
              <Route path='/admin/videoCarousel/search'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><VideoCarousel /></React.Suspense>} />
              <Route path='/admin/videoCarousel/page/:pageNumber'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><VideoCarousel /></React.Suspense>} />
              <Route path='/admin/videoCarousel/search/:keyword/page/:pageNumber'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><VideoCarousel /></React.Suspense>} />

              <Route path='/admin/VideoCreateScreen' element={<React.Suspense fallback={<><SpinnerIcon /></>}><VideoCreateScreen /></React.Suspense>} /> 
              <Route path='/admin/VideoCreateScreen/search/:keyword'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><VideoCreateScreen /></React.Suspense>} />
              <Route path='/admin/VideoCreateScreen/search/carouselAll/:keyword'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><VideoCreateScreen /></React.Suspense>} />
              <Route path='/admin/VideoCreateScreen/page/:pageNumber'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><VideoCreateScreen /></React.Suspense>} />
              <Route path='/admin/VideoCreateScreen/search/:keyword/page/:pageNumber'  element={<React.Suspense fallback={<><SpinnerIcon /></>}><VideoCreateScreen /></React.Suspense>} />

            
          

              <Route path='/admin/contactUs' element={<React.Suspense fallback={<><SpinnerIcon /></>}><Contact /></React.Suspense>} /> 
              
            </Route>
          </Routes>
          </Container>
           {/* <Container style={{ marginLeft: 20, backgroundColor: 'blue', float:'left', width: '25%', height: '87wh'}}><h1>RIGHT</h1></Container> */}
      </main>
      {/* <Container style={{ marginLeft: 20, backgroundColor: 'orange', float:'left', width: '25%', height: '87wh'}}><h1>RIGHT</h1></Container> */}
    </Router>
   
  
  );
}

export default App;







