import React from 'react'
 import ScrollToTop from "react-scroll-to-top";

const PrivacyPolicy = () => {

  return (

    <div style={{fontSize: 16, marginTop: '2rem', marginBottom: '2rem'}}>

       <h4 style={{fontSize: 16, margin: '2rem', textAlign: 'center'}}>PRIVACY POLICY</h4> 

                    <p style={{fontSize: 16}}>
                        
                      <p style={{fontSize: 20, textAlign: 'center', fontWeight: 'bold', margin: '4rem'}}>Introduction</p>

                    Golgii respects your privacy and is committed to protecting it. We provide this Privacy Policy to inform you of our privacy procedures and practices, as well as to describe what information we collect about you, how we obtain your information, and how we use, share and otherwise process your information in the course of operating our business and our platforms.
                    In this Privacy Policy, “we”, “our” and “us” refers to Golgii, the website and our mobile applications together are the “platforms”; and “you” and “your” refers to a specific individual accessing and using the platforms or the services that we provide.
                    We may update or revise this Privacy Policy at any time and this page will always reflect thecurrent version.

                   
                    <p style={{fontSize: 20, textAlign: 'center', fontWeight: 'bold', margin: '4rem'}}>The information that we collect</p>

                    For purposes of this Privacy Policy, Golgii is a “data controller”, which means that we are responsible for the personal information that we collect about you and how we process it.
                    "Personal information" is any information that can be used to identify you or that we can link to you and which we have in our possession or control.
                    The information we may collect from or about you includes:
                    Personal data about you: When you register on the website as a user or otherwise, update your information, take part in promotions or campaigns or contests, sign into our mobile application using your personal information or a social media account, send emails to us, send support requests, contact estate agents via forms or phone numbers made available on our platforms, provide feedback, or when you report a problem with our platforms, you provide us with information that we store and process. Such information may include your name, address, phone number, email address, device IP address, date of birth, passport or Emirates ID, survey participation, video and audio recordings and your interests, and any other information that you independently choose to provide to us (for example, if you send us an email or call us).
                    <br />
                    Purchase information: We may collect information relating to any purchases or potential purchases of our products or services that you make (such as records of purchase).
                    <br />
                    Website information: We may receive information from you as a result of your visits to, and use of, the website. This information may include information such as your IP address, the date and time you access the website, length of time you spend on the website, your browsing history (recorded by the text and graphics files that compose that page), and the Internet address of the website from which you redirected to our website.
                    <br />
                    {/* Financial, payment and credit data: We may collect payment data that you provide when you make online purchases. This may include your payment instrument number (e.g., credit card, debit card), your name and billing address, and the security code associated with your payment instrument (e.g., the CSV or CVV). We may collect information relating to payment and transaction history and billing information. We may also collect personal credit information, credit reports provided by a credit reporting agency used to report on overdue credit payments and commercial credit information for the purpose of .
                    <br /> */}
                    {/* Other information: We may collect other information so that we, including our affiliates, can provide our services to you, such as credit scores, payslips and salary letters for the purpose of obtaining a mortgage. If you are a user of any of our products or services, we may collect information you have made publicly available. */}
                    <br />

                    
                    <p style={{fontSize: 20, textAlign: 'center', fontWeight: 'bold', margin: '4rem'}}>How do we obtain and what are the sources of your information</p>

                    We may collect your information during the course ofour relationship with you and will only use your personal information in accordance with applicable data protection laws.
                    We may obtain this information from a variety of sources, including:
                    from you (for example, when you access and use the platforms, when you make an inquiry about our platforms or services, send WhatsApp communications to us or agents and brokers via our platforms to enquire about a property, send emails or call our Customer Care team, or otherwise provide us with your personal information);
                    <br />
                    from advertising networks, analytics providers and search information providers.

                   
                    
                    <p style={{fontSize: 20, textAlign: 'center', fontWeight: 'bold', margin: '4rem'}}>How do we process your information</p>

                    We will comply with applicable data protection laws that apply to our processing of your personal information. We will:
                    process your personal information in a lawful, fair, transparent and secure way;
                    <br />
                    collect your personal information only for specific, explicit and legitimate purposes as explained to you when collecting your personal information;
                    <br />
                    not use your personal information in any way that is incompatible with those purposes;
                    <br />
                    process your personal information in a manner that is adequate and relevant to the purposes for which we have collected it and limited only to those purposes;
                    <br />
                    keep your personal information accuGolgii and, where necessary, up to date; and
                    <br />
                    keep your personal information in a form that identifies you only as long as necessary for the purposes we have informed you and/or as permitted by law.
                    How do we use your information
                    We may use or process your personal information for the following purposes:
                    to provide any services that you request from us;
                    <br />
                    to provide a better service to you, and in particular, to keep internal records, as well as to improve our products, services and the platforms. If you contact us via the website, or via email to the contacts set out on the website, or via our call centres, we may keep a record of that correspondence or call to improve our services and to address any specific requests or complaints;
                    <br />
                    {/* to communicate with you (through various communication platforms, such as WhatsApp, SMS, phone and email) if you have ordered, purchased or participated in anything on the platforms, including to respond to any queries, requests or comments that you may have and to facilitate communications between you and our real estate agents and brokers when you enquire about the properties on our platforms;
                    <br /> */}
                    {/* to assist third parties in providing services to you, such as real estate developers when sending emails on their behalf and banks with respect of issuing mortgages; */}
                    {/* <br /> */}
                    to contact you for market research purposes;
                    <br />
                    to monitor communications in order to improve the services we provide to you and assess the performance and responsiveness of our employees.
                    <br />
                    to provide you with information about new opportunities, promotions, alert notifications, special offers and other information that we may feel is relevant to you;
                    <br />
                    to provide you and enable selected third parties to provide you, with information about goods or services that may interest you;
                     <br />
                    to customize or enhance your experience on our platforms and services, including via the display of adverts and direct communications you receive from us, which may be based on your activity on our platforms;
                    <br />
                    to improve our platforms by determining the website’s technical design specifications and identifying system performance or problem areas, for tracking activity on the website and mobile applications, preparing reports, assessing trends and otherwise monitoring the way in which the platforms are being used;
                    <br />
                    to process your payments or any documents that you provide via the platforms;
                    <br />
                    to measure or understand the effectiveness of advertising we serve to you and others, to deliver relevant advertising to you, to make suggestions and recommendations to you and other users of our website about goods and services that may interest you or them, which may be based on your activity on our website or third parties’ website(s); and
                    <br />
                    to comply with legal obligations.
                    <br />

                    
                    <p style={{fontSize: 20, textAlign: 'center', fontWeight: 'bold', margin: '4rem'}}>On what basis do we use your information</p>

                    We use your personal information on the following bases (where permitted in accordance with applicable data protection laws):
                    because the information is necessary for the performance of a contract with you or to take steps at your request to enter into a contract;
                    <br />
                    because you have given your consent (if we expressly ask for consent to process your personal information, for a specific purpose);
                    <br />
                    for our legitimate interests. Using your personal information helps us to operate, improve and minimise any disruption to the platforms and the services that we may offer to you. We also have a legitimate interest in sending you information on the services we believe will be of interest to you;
                    <br />
                    to comply with legal and regulatory obligations; or
                    <br />
                    if you have made the personal information publicly available.

                    <p style={{fontSize: 20, textAlign: 'center', fontWeight: 'bold', margin: '4rem'}}>Who do we share your information with</p>
                    
                    We will only disclose or transfer your personal information for the purposes set out in this notice:
                    {/* to selected real estate brokers, real estate brokerage firms, agents, developers or other service providers that we use, where asked to do so or as part of your use of the platforms. For example, when you enquire about a property of interest to you, we will pass your personal information to the estate agents handling that property sale or rental;
                    <br /> */}
                    to business partners, suppliers and sub-contractors for the performance of any contract we enter into with them or you;
                    <br />
                    to any member of our group;
                    <br />
                    to any competent authorities if we are legally required to comply with a request for data;
                    <br />
                    to our third-party service providers. We partner with and are supported by service providers around the world. Personal information will be made available to these parties only when necessary to fulfil the services they provide to us, such as software, system, and platform support; direct marketing services; social media and communication services; cloud hosting services; advertising; data analytics; and order fulfilment and delivery. Our third-party service providers are not permitted to share or use personal information we make available to them for any other purpose other than to provide services to us;
                    
                    <br />
                    if we sell or buy any business or assets, in which case we may disclose your personal information to the prospective seller or buyer of such business or assets, along with its professional advisers. If our, or any of our affiliates assets are acquiredby a third party, personal information held by us or our affiliates about our customers will be one of the transferred assets;
                    <br />
                    if required in order to obtain professional advice;
                    <br />
                    {/* to perform a credit check and to contact other lenders, banks, or financial institutions to understand your financial situation with respect to providing mortgages to you through Mortgage Finder, and this may involve us, or such lenders and institutions obtaining information from a relevant credit reporting agency or source that provides information about your financial standing;
                    <br /> */}
                    if we are under a duty to disclose or share your personal information in order to comply with any legal obligation, or in order to enforce or apply our terms of use and other agreements; and
                    <br />
                    to protect our rights, property, or safety, as well as that of our affiliates, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.
                    Countries to which we transfer your information
                    The information that we collect from you may be transferred to and stored and hosted outside of the country from which you may be accessing the platforms, and may be transferred to countries which do not have data protection laws or to countries where your privacy and other fundamental rights will not be protected as extensively. For example, your personal information may be processed by staff operating outside such country, who work for us or for one of our suppliers. These and all other transmissions will remain secure and under our control.
                    We will implement appropriate measures to ensure that your personal information remains protected and secure when it is transferred outside your home country and you can exercise your rights effectively.
                    The security of your information
                    Keeping your personal information secure and preventing unauthorized access is of utmost priority to us, and we take all steps reasonably necessary to protect your personal information against any unauthorised access, use, alteration, disclosure or destruction. In addition, we limit access to your personal information to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal information on our instructions and they are subject to a duty of confidentiality.
                    Where we have given you, or where you have created a password which is part of our services or allows you to access parts of our platforms, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.
                    Unfortunately, the transmission of information via the internet is not completely secure. We will do our best to protect your personal information, however, we cannot guarantee the security of your information transmitted to our platforms. Any transmission of information made, is done so at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.
                    Keeping your information
                    How long we hold your personal information for will vary, and we will typically keep your personal information only for as long as is necessary to respond to any queries or complaints, to improve the services that we offer to you or to comply with any legal obligations to which we may be subject.
                    To determine the appropriate retention periods for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorised use or disclosure of your personal information, the purposes for which we process your personal information and whether we can achieve such purposes through other means, and the applicable legal requirements.
                    Your choices and rights
                    Under certain circumstances and under certain applicable data protection laws, you may have the right to:
                    Access your data – request a copy of your personal information that we process about you;
                    <br />
                    Correct your data – request us to amend or update your personal information where it is inaccurate or incomplete;

                    Erase your data – request us to delete your personal information where it is no longer necessary for the purpose(s) for which your information was originally collected;

                    Restrict your data – request us temporarily or permanently to stop processing all or some of your personal data;

                    Object to the use of your data – at any time, object to us processing your personal information where it is based exclusively on our legitimate interests (see above) or for direct marketing purposes;
                    <br />
                    Receive or transmit your data in a machine-readable and structured format (otherwise known as "data portability") – request the receipt or transmission of your personal information to another organisation, in a structured and machine-readable format; and
                    <br />
                    Withdraw your consent – withdraw your consent at any time to the use of your personal information for a particular purpose (where we have asked you for consent to use your information for that particular purpose). Please take note that in certain circumstances it may be lawful for us to continue processing without your consent if we have another legal basis (other than consent) for doing so.
                    <br />
                    For any of the above, please email us at privacy@Golgii.ae. Subject to any overriding legal obligations, requirements and/or exemptions, we will endeavour to respond to your request as soon as possible, unless we require further information from you. We may also ask you to provide proof of your identity.
                    How to manage your marketing preferences
                    To opt out of email marketing, you can use the unsubscribe link found in the email communication you receive from us or by sending an email to privacy@Golgii.ae. For other marketing preferences, you can contact us via any relevant link in the marketing communication, or by sending an email to privacy@Golgii.ae. Information related to controlling cookies can be found in our Cookies Policy.
                    Hyperlinks
                    Our services may contain links to and may be used by you in conjunction with third-party apps, services, tools, and websites that are not affiliated with, controlled, or managed by us. Examples include but are not limited to Facebook, LinkedIn, Twitter and, third-party apps like voice software and readers. The privacy practices of these third parties will be governed by the parties’ own privacy policies. We are not responsible for the security or privacy of any information collected by these third parties. You should review the privacy policies applicable to these third-party services.
                    Information that you provide to third-party companies is not covered by this Privacy Policy.
                    Privacy of minors
                    We are committed to protecting the privacy needs of children and we encourage parents and guardians to take an active role in their children's online activities and interests. We do not knowingly collect information from children and we do not target our platforms to children. If you become aware that a child has provided us with personal information without the parent or guardian’s consent, please contact us a privacy@Golgii.ae and we will take steps to delete the information from our records.
                    Updates or changes to this Privacy Policy
                    We may update this Privacy Policy from time to time as we deem necessary. If there are material changes to this Privacy Policy, we will provide notice where, and in the manner required, by applicable laws, including but not limited to by email or via the platforms. Any changes to this Privacy Policy take effect immediately after being notified by us.
                    How do you contact us
                    This Privacy Policy sets out in broad terms how we handle your personal information and safeguard your privacy. If you have any questions relating to our Privacy Policy, please send an email to privacy@Golgii.ae or a postal mail to the following address:
                    Data Protection Officer, Golgii. 
                    {/* Office 777 in Golgii Tower, Dubai Internet City, Dubai, UAE. */}
                    </p>

              <div style={{display: 'flex-end', float: 'right', marginTop: 10, marginLeft: 10}}><ScrollToTop smooth style={{position: 'relative', alignSelf: 'flex-end'}} /></div>
    </div>
  )
}

export default PrivacyPolicy