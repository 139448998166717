import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Carousel, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
// import Message from './Message'
//import { listTopProducts } from '../actions/productActions'
import { getMyImageContent } from '../actions/contentActions'

const ImageCarousel = () => {
  const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin


    const  myImagesList = useSelector((state) => state.myImagesList)
    const { loading} =  myImagesList


   useEffect(() => {
    dispatch(getMyImageContent(userInfo._id))
  }, [dispatch, userInfo._id])



    let finalfilteredImages

    if(!myImagesList.contents){
        finalfilteredImages = {}
    } else {
    finalfilteredImages = myImagesList.contents.filter(filtercontent => filtercontent.resource_type === 'image')
    }


  return loading ? (
    <Loader/>
   
  ) 
//   : error ? (
//     <Message variant='danger'>{error}</Message>
//   ) 
  : (
    <>
       <Carousel pause='hover' className='bg-primary' >
         {/* <div style={{ color: '#ffffff'}} > */}
                  {/* <h5>Enjoy pictures @Real-Experience</h5> */}
          {/* </div> */}

      {finalfilteredImages.map((img) => (
        <Carousel.Item key={img._id} >
          <Link to={`/product/${img._id}`} >
            <div className="d-flex flex-row"  style={{ width: '100%'}} >
              
                <div style={{width:'52%'}} >
                  <Image style={{width:'100%'}} src={img.secure_url} alt={img.resource_type} fluid />
                </div>
                {/* <div style={{width:'45%', color: '#ffffff'}} >
                  <h5>Enjoy pictures @Real-Experience</h5>
                </div>
                */}
            </div>
          
            <Carousel.Caption className='carousel-caption' >
              <h2 style={{color: '#ffffff'}}>
                {/* {product.name} (${product.price}) */}
                Party Time!
              </h2>
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
      ))}
      
    </Carousel>
    </>
   
  )
}

export default ImageCarousel