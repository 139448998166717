import { combineReducers } from "redux";
import { profilePictureReducer, mailReducer, addProfilePicReducer,  userListReducer, userFollowReducer, userLoginReducer,  userRegisterReducer, userDetailsReducer, userProfileUpdateReducer  } from './reducers/userReducers'
import  { contentShareMarkedReducer, contentShareReducer, contentVideoToPublicReducer, contentMarkedDeleteReducer,videosCarouselListReducer, companyVideosListReducer, contentDeleteReducer, contentLikeReducer, commentCreateReducer, videosListReducer , myVideosListReducer, contentVideoAddReducer, myImagesListReducer, contentImageAddReducer}  from './reducers/contentReducers.js'
//import {spaceDetailsReducer, spaceCreatePostReducer, spaceCreateReducer,spaceListReducer } from './reducers/spaceReducers'

const rootReducer = combineReducers({
    userLogin: userLoginReducer,
	userRegister: userRegisterReducer,
  	userDetails: userDetailsReducer,
    myVideosList: myVideosListReducer,
    videosList: videosListReducer ,
    contentVideoAdd: contentVideoAddReducer,
    myImagesList: myImagesListReducer, 
    contentImageAdd: contentImageAddReducer,
    commentCreate: commentCreateReducer,
    contentLike: contentLikeReducer,
    contentDelete: contentDeleteReducer,
    companyVideosList: companyVideosListReducer,
    addProfilePic: addProfilePicReducer,
    videosCarouselList: videosCarouselListReducer,
    userFollow: userFollowReducer,
    contentMarkedDelete: contentMarkedDeleteReducer,
    userList: userListReducer,
    contentVideoToPublic: contentVideoToPublicReducer,
    contentShare: contentShareReducer,
    contentShareMarked: contentShareMarkedReducer,
    mail: mailReducer,
    profilePicture: profilePictureReducer,
    // contentMyDelete: contentMyDeleteReducer,
	//   contentCreate: contentCreateReducer,
    // contentList: contentListReducer,
    // contentDetails: contentDetailsReducer,
    // contentListMy: contentListMyReducer,
    // contentLike: contentLikeReducer,
    // contentReviewCreate: contentReviewCreateReducer,
    // commentReviewCreate: commentReviewCreateReducer,
    // contentUserProfileList: contentUserProfileListReducer,
    // editedPostContent: editedPostContentReducer,
    // blockUserContent: blockUserContentReducer,
    // unBlockUserContent: unBlockUserContentReducer,
  
    // contentSearchList: contentSearchListReducer,
    // contentEditStatus: editStatusReducer,
    // spaceCreate: spaceCreateReducer,
    // spaceList: spaceListReducer,
    // spaceCreatePost: spaceCreatePostReducer,
    // spaceDetails: spaceDetailsReducer  
})

export default rootReducer