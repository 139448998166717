export const VIDEO_LOADING = 'VIDEO_LOADING'
export const ADD_VIDEO= 'ADD_VIDEO'
export const GET_MYVIDEOS = 'GET_MYVIDEOS'
export const UPDATE_LIKES = 'UPDATE_LIKES';
export const ADD_PIC = 'ADD_PIC';
export const  GET_PICS = 'GET_PICS'
export const  GET_PIC = 'GET_PIC';
export const VIDEO_ERROR = 'VIDEO_ERROR';
export const  GET_ERRORS = 'GET_ERRORS';
export const PIC_LOADING = 'PIC_LOADING' 

export const CONTENT_ADD_VIDEO_REQUEST = 'CONTENT_ADD_VIDEO_REQUEST'
export const CONTENT_ADD_VIDEO_SUCCESS = 'CONTENT_ADD_VIDEO_SUCCESS'
export const CONTENT_ADD_VIDEO_FAIL = ' CONTENT_ADD_VIDEO_FAIL'

export const CONTENT_VIDEO_DETAILS_REQUEST = 'CONTENT_VIDEO_DETAILS_REQUEST'
export const CONTENT_VIDEO_DETAILS_SUCCESS = 'CONTENT_VIDEO_DETAILS_SUCCESS'
export const CONTENT_VIDEO_DETAILS_FAIL = ' CONTENT_VIDEO_DETAILS_FAIL'

export const MY_VIDEO_LIST_REQUEST = 'MY_VIDEO_LIST_REQUEST'
export const MY_VIDEO_LIST_SUCCESS = 'MY_VIDEO_LIST_SUCCESS'
export const MY_VIDEO_LIST_FAIL = ' MY_VIDEO_LIST_FAIL'

export const COMPANY_VIDEO_LIST_REQUEST = 'COMPANY_VIDEO_LIST_REQUEST'
export const COMPANY_VIDEO_LIST_SUCCESS = 'COMPANY_VIDEO_LIST_SUCCESS'
export const COMPANY_VIDEO_LIST_FAIL = ' COMPANY_VIDEO_LIST_FAIL'


export const VIDEO_LIST_REQUEST = 'VIDEO_LIST_REQUEST'
export const VIDEO_LIST_SUCCESS = 'VIDEO_LIST_SUCCESS'
export const VIDEO_LIST_FAIL = ' VIDEO_LIST_FAIL'

export const ALL_VIDEO_LIST_REQUEST = 'ALL_VIDEO_LIST_REQUEST'
export const ALL_VIDEO_LIST_SUCCESS = 'ALL_VIDEO_LIST_SUCCESS'
export const ALL_VIDEO_LIST_FAIL = 'ALL_VIDEO_LIST_FAIL'

export const CONTENT_ADD_IMAGE_REQUEST = 'CONTENT_ADD_IMAGE_REQUEST'
export const CONTENT_ADD_IMAGE_SUCCESS = 'CONTENT_ADD_IMAGE_SUCCESS'
export const CONTENT_ADD_IMAGE_FAIL = ' CONTENT_ADD_IMAGE_FAIL'

export const COMMENT_CREATE_REQUEST = 'COMMENT_CREATE_REQUEST'
export const COMMENT_CREATE_SUCCESS = 'COMMENT_CREATE_SUCCESS'
export const COMMENT_CREATE_FAIL = ' COMMENT_CREATE_FAIL'

export const MY_IMAGE_LIST_REQUEST = 'MY_IMAGE_LIST_REQUEST'
export const MY_IMAGE_LIST_SUCCESS = 'MY_IMAGE_LIST_SUCCESS'
export const MY_IMAGE_LIST_FAIL = ' MY_IMAGE_LIST_FAIL'

export const CONTENT_LIKE_REQUEST = 'CONTENT_LIKE_REQUEST'
export const CONTENT_LIKE_SUCCESS = 'CONTENT_LIKE_SUCCESS'
export const CONTENT_LIKE_FAIL = 'CONTENT_LIKE_FAIL'

export const CONTENT_DELETE_REQUEST = 'CONTENT_DELETE_REQUEST'
export const CONTENT_DELETE_SUCCESS = 'CONTENT_DELETE_SUCCESS'
export const CONTENT_DELETE_FAIL = 'CONTENT_DELETE_FAIL'

export const CONTENT_MARKED_DELETE_REQUEST = 'CONTENT_MARKED_DELETE_REQUEST'
export const CONTENT_MARKED_DELETE_SUCCESS = 'CONTENT_MARKED_DELETE_SUCCESS'
export const CONTENT_MARKED_DELETE_FAIL = 'CONTENT_MARKED_DELETE_FAIL'

export const CONTENT_TOPUBLIC_REQUEST = 'CONTENT_TOPUBLIC_REQUEST'
export const CONTENT_TOPUBLIC_SUCCESS = 'CONTENT_TOPUBLIC_SUCCESS'
export const CONTENT_TOPUBLIC_FAIL = 'CONTENT_TOPUBLIC_FAIL'

export const CONTENT_SHARE_REQUEST = 'CONTENT_SHARE_REQUEST'
export const CONTENT_SHARE_SUCCESS = 'CONTENT_SHARE_SUCCESS'
export const CONTENT_SHARE_FAIL = 'CONTENT_SHARE_FAIL'


export const CONTENT_MARKED_SHARE_REQUEST = 'CONTENT_MARKED_SHARE_REQUEST'
export const CONTENT_MARKED_SHARE_SUCCESS = 'CONTENT_MARKED_SHARE_SUCCESS'
export const CONTENT_MARKED_SHARE_FAIL = 'CONTENT_MARKED_SHARE_FAIL'







