
import { 
  CONTENT_ADD_VIDEO_REQUEST, 
  CONTENT_ADD_VIDEO_SUCCESS, 
  CONTENT_ADD_VIDEO_FAIL, 
  MY_VIDEO_LIST_REQUEST, 
  MY_VIDEO_LIST_SUCCESS,  
  MY_VIDEO_LIST_FAIL, 
  CONTENT_ADD_IMAGE_REQUEST, 
  CONTENT_ADD_IMAGE_SUCCESS, 
  CONTENT_ADD_IMAGE_FAIL, 
  MY_IMAGE_LIST_REQUEST, 
  MY_IMAGE_LIST_SUCCESS,  
  MY_IMAGE_LIST_FAIL,
  ALL_VIDEO_LIST_REQUEST,
  ALL_VIDEO_LIST_SUCCESS,
  ALL_VIDEO_LIST_FAIL,
  COMMENT_CREATE_REQUEST,
  COMMENT_CREATE_SUCCESS,
  COMMENT_CREATE_FAIL,
  CONTENT_LIKE_REQUEST,
  CONTENT_LIKE_SUCCESS,
  CONTENT_LIKE_FAIL,
  CONTENT_DELETE_REQUEST,
  CONTENT_DELETE_SUCCESS,
  CONTENT_DELETE_FAIL,
  COMPANY_VIDEO_LIST_REQUEST,
  COMPANY_VIDEO_LIST_SUCCESS,
  COMPANY_VIDEO_LIST_FAIL,
  CONTENT_MARKED_DELETE_REQUEST,
  CONTENT_MARKED_DELETE_SUCCESS,
  CONTENT_MARKED_DELETE_FAIL,
  CONTENT_TOPUBLIC_REQUEST,
  CONTENT_TOPUBLIC_SUCCESS,
  CONTENT_TOPUBLIC_FAIL,
   CONTENT_SHARE_REQUEST,
  CONTENT_SHARE_SUCCESS,
  CONTENT_SHARE_FAIL,
  CONTENT_MARKED_SHARE_REQUEST,
  CONTENT_MARKED_SHARE_SUCCESS,
  CONTENT_MARKED_SHARE_FAIL,

  
} from  "../constants/contentConstants"


export const contentVideoAddReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTENT_ADD_VIDEO_REQUEST:
            return { loading: true }
        case CONTENT_ADD_VIDEO_SUCCESS:
            return { loading: false, success: true, content: action.payload}
        case CONTENT_ADD_VIDEO_FAIL:
            return { loading: false, error: action.payload}
        default: 
            return state

    }
}   


export const videosListReducer = (state = { contents: [] }, action) => {
  switch (action.type) {
    case ALL_VIDEO_LIST_REQUEST:
      return { 
        loading: true, 
        contents: [] 
      }
    case ALL_VIDEO_LIST_SUCCESS:
       return { 
        loading: false, 
        contents: action.payload.contents, pages: action.payload.pages, page: action.payload.page, userInfoNode: action.payload.userInfoNode,
      }
    case ALL_VIDEO_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}


export const videosCarouselListReducer = (state = { contents: [] }, action) => {
  switch (action.type) {
    case ALL_VIDEO_LIST_REQUEST:
      return { 
        loading: true, 
        contents: [] 
      }
    case ALL_VIDEO_LIST_SUCCESS:
       return { 
        loading: false, 
        contents: action.payload.contents,
      }
    case ALL_VIDEO_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}





export const myVideosListReducer = (state = { contents: [] }, action) => {
  switch (action.type) {
    case MY_VIDEO_LIST_REQUEST:
      return { 
        loading: true, 
        contents: [] 
      }
    case MY_VIDEO_LIST_SUCCESS:
       return { 
         loading: false, 
        contents: action.payload.contents, pages: action.payload.pages, page: action.payload.page, userInfoNode: action.payload.userInfoNode,
      }
    case MY_VIDEO_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}


export const companyVideosListReducer = (state = { contents: [] }, action) => {
  switch (action.type) {
    case COMPANY_VIDEO_LIST_REQUEST:
      return { 
        loading: true, 
        contents: [] 
      }
    case COMPANY_VIDEO_LIST_SUCCESS:
       return { 
         loading: false, 
        //contents: action.payload,
        contents: action.payload.contents, pages: action.payload.pages, page: action.payload.page, userInfoNode: action.payload.userInfoNode,
      }
    case COMPANY_VIDEO_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}



export const contentImageAddReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTENT_ADD_IMAGE_REQUEST:
            return { loading: true }
        case CONTENT_ADD_IMAGE_SUCCESS:
            return { loading: false, success: true, content: action.payload}
        case CONTENT_ADD_IMAGE_FAIL:
            return { loading: false, error: action.payload}
            return {}
        default: 
            return state

    }
}   

export const myImagesListReducer = (state = { contents: [] }, action) => {
  switch (action.type) {
    case MY_IMAGE_LIST_REQUEST:
      return { 
        loading: true, 
        contents: [] 
      }
    case MY_IMAGE_LIST_SUCCESS:
       return { 
        loading: false, 
        // contents: action.payload,
        contents: action.payload.contents, pages: action.payload.pages, page: action.payload.page,
      }
    case MY_IMAGE_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}


export const commentCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMENT_CREATE_REQUEST:
      return { loading: true }
    case COMMENT_CREATE_SUCCESS:
      return { loading: false, success: true }
    case COMMENT_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const contentLikeReducer = (state = {content:{}}, action) => {
  switch (action.type) {
    case CONTENT_LIKE_REQUEST:
      return { loading: true}
    case CONTENT_LIKE_SUCCESS:
      return { loading: false, success: true, content: action.payload}
    case CONTENT_LIKE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

// export const contentShareReducer = (state = {content:{}}, action) => {
//   switch (action.type) {
//     case CONTENT_SHARE_REQUEST:
//       return { loading: true}
//     case CONTENT_SHARE_SUCCESS:
//       return { loading: false, success: true, content: action.payload}
//     case CONTENT_SHARE_FAIL:
//       return { loading: false, error: action.payload }
//     default:
//       return state
//   }
// }



export const contentDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTENT_DELETE_REQUEST:
            return { loading: true }
        case CONTENT_DELETE_SUCCESS:
            return { loading: false, success: true}
        case CONTENT_DELETE_FAIL:
            return { loading: false, error: action.payload}
        default: 
            return state

    }
}

export const contentMarkedDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case CONTENT_MARKED_DELETE_REQUEST:
            return { loading: true }
        case CONTENT_MARKED_DELETE_SUCCESS:
            return { loading: false, success: true}
        case CONTENT_MARKED_DELETE_FAIL:
            return { loading: false, error: action.payload}
        default: 
            return state

    }
}

export const contentVideoToPublicReducer = (state = {content:{}}, action) => {
  switch (action.type) {
    case CONTENT_TOPUBLIC_REQUEST:
      return { loading: true}
    case CONTENT_TOPUBLIC_SUCCESS:
      return { loading: false, success: true, content: action.payload}
    case CONTENT_TOPUBLIC_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}


export const contentShareReducer = (state = {content:{}}, action) => {
  switch (action.type) {
    case CONTENT_SHARE_REQUEST:
      return { loading: true}
    case CONTENT_SHARE_SUCCESS:
      return { loading: false, success: true, content: action.payload}
    case CONTENT_SHARE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}


export const contentShareMarkedReducer = (state = {content:{}}, action) => {
  switch (action.type) {
    case CONTENT_MARKED_SHARE_REQUEST:
      return { loading: true}
    case CONTENT_MARKED_SHARE_SUCCESS:
      return { loading: false, success: true, content: action.payload}
    case CONTENT_MARKED_SHARE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}





