

import React, {useEffect} from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import moment from 'moment'
import { getCompanyVideoContent, deleteContent, getAllVideoContent } from '../actions/contentActions'
import PaginateVideoList from '../components/PaginateVideoList'
import { Link, NavigationType, useNavigate, useParams  } from 'react-router-dom'



const CompanyRatedVideoList = () => {


    const { keyword, pageNumber: pageN } = useParams();

//   console.log('KEYWORD ALL VIDEOS', pageN)

  const pageNumber = pageN || 1

    

    // const keyword = ''

    const dispatch = useDispatch()

     const  videosList= useSelector((state) => state.videosList)
    const { userInfoNode } =  videosList

    // console.log('USER NODE TYPE', typeof userInfoNode)

      const companyVideosList = useSelector((state) => state.myVideosList)
    const { loading, error, contents, page, pages} = companyVideosList

    // console.log('USER COMPANY CONTROLLER', user)


    //  console.log('USERNODEINFO 1', userInfoNode)

    //const userFiltered = Object.entries(userInfoNode).find(([ key, value ]) => key === 'companyName')

     //const userFiltered = Object.entries(userInfoNode).find(([ key, value ]) => value === 'Nike')
    
    //const userFiltered = Object.values(userInfoNode).find(k => userInfoNode[k] === companyName);
    //     const userFiltered = Object.values(userInfoNode).filter(k => userInfoNode[k] === 'companyName');

    //console.log('USER FILTERED', userFiltered )


    //  let userInfoNodeElements 

    // if(userInfoNode){
    //     Object.entries(userInfoNode).forEach(([key, value]) => 

    //     //   `${key}: ${value}`

    //     //   console.log('USER NODEINFO', `${key}: ${value}`)

    //       userInfoNodeElements = `${key}: ${value}`

         

    //     );
    //   } 

       


        //  console.log('USERNODE INFO', JSON.stringify(userInfoNode))



      useEffect(() => {

        dispatch(getAllVideoContent(keyword, pageNumber))
   
       dispatch(getCompanyVideoContent(userInfoNode.companyName, keyword, pageNumber))
     
    }, [ dispatch, keyword, pageNumber])


    



     const contentDelete = useSelector((state) => state.contentDelete)
    const { loading: loadingDelete, error: errorDelete, success: successDelete} = contentDelete

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo} = userLogin

    console.log('COMPANY NAME',  userLogin)

    // useEffect(() => {


    //     if(userInfo || userInfo.isAdmin) {

    //          dispatch(getMyVideoContent(userInfo._id, keyword, pageNumber))
           
    //     } 
    
    // }, [dispatch, userInfo._id, keyword, pageNumber])


    // const companyNameFiltered = contents.filter(content => content._)

    //--------------------

//     const companyNameElement = userInfoNodeElements

    

     const companyName = ''


//    console.log('COMPANY NAME 2', {companyName})


     useEffect(() => {

        // dispatch(getAllVideoContent(keyword, pageNumber))
   
      dispatch(getCompanyVideoContent( companyName, keyword, pageNumber))
     
    }, [ dispatch, keyword, pageNumber])



    


    const deleteHandler = (id) => {
        if(window.confirm('Are you sure')) {
            dispatch(deleteContent(id))
        }
        
    }



    return (
         <div style={{justifyContent: 'center', flexDirection: 'row', height: '85vh'}}>
         <Row className='align-items-center' >
             <Col style={{ alignSelf:'center'}} alignSelf='center'>
                <h3 style={{textAlign: 'center', marginTop: 30, marginBottom: 20, }} >Company Rated Video List - {" "} {contents.length}</h3>
             </Col>
             
         </Row>

         {loadingDelete && <Loader /> }
         {errorDelete && <Message variant='danger'>{errorDelete}</Message>}

        

            {loading ? (
                <Loader />
            ) : error ? (
                <Message variant='danger'>{error}</Message>
            ) : (
                <>
                <Table striped bordered hover responsive className='table-sm'>
                <thead>
                    <tr>
                    {/* <th>ID</th> */}
                    <th>CREATED</th>
                    <th>SEARCH ID</th>
                    <th>NAME</th>
                    <th>REVIEW COMPANY</th>
                    <th>CATEGORY</th>
                    <th>BRAND</th>
                    <th>RATING</th>                   
                    <th>LIKES</th>
                    <th>COMMENTS</th>
                    <th>URL</th>
                    <th>DELETE</th>
                    </tr>
                </thead>
                <tbody>
                    {contents.map((content) => (
                    <tr key={content._id}>
                        <td> {moment(content.createdAt).format("DD/MM/YYYY , h:mm a")}</td>
                        <td>{content.contentNanoidId}</td>
                        <td>{content.companyName}</td>
                        <td>{content.reviewCompanyName}</td>
                        <td>{content.category}</td>
                        <td>{content.brand}</td>
                        <td>{content.rating}</td>
                        <td>{content.likes.length}</td>
                        <td>{content.comments.length}</td>
                       
                        <td style={{color:'blue'}} onClick={() => window.open(content.secure_url) } >{content.secure_url}</td>
                      
                        <td>
                        {/* <LinkContainer to={`/admin/content/${content._id}/edit`}>
                            <Button variant='light' className='btn-sm'>
                            <i className='fas fa-edit'></i>
                            </Button>
                        </LinkContainer> */}
                        <Button
                            variant='danger'
                            className='btn-sm'
                            onClick={() => deleteHandler(content._id)}
                        >
                            <i className='fas fa-trash'></i>
                        </Button>
                        </td>
                    </tr>
                    ))}
                </tbody>
                </Table>
                
                <PaginateVideoList 
                    pages={pages}
                    page={page}
                    keyword={keyword ? keyword : ''}
                // pages={pages} page={page} isAdmin={true}
                />
                </>
            )}
            </div>
    )
}

export default React.memo(CompanyRatedVideoList)










