import React from 'react'

const Logo = () => {
    return (
        
       
            <svg  style={{height: 60,  width: 100, justifyContent: 'center', alignItems: 'center',marginLeft: 20}}>
                <defs>
                    <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%"
                    style={{stopColor: 'rgb(0,0,0)', stopOpacity: 1}} />
                    <stop offset="100%"
                    style={{stopColor: 'rgb(0,255,255)', stopOpacity: 1}} />
                    </linearGradient>
                </defs>
                <ellipse cx="40" cy="30" rx="40" ry="30" fill="url(#grad1)" />
                <text 
                    // textTransform='lowercase'
                    fill="#ffffff" 
                    fontSize="30" 
                    // fontFamily="Verdana"
                    fontFamily='Cookie-Regular'
                    x="12" 
                    y="38"
                    style={{textTransform:'lowercase'}}

                   
                    >Golgii
                        
                    </text>
                Sorry, your browser does not support inline SVG.
            </svg>
    
    )
}

export default Logo
