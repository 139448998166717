

import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Route } from 'react-router-dom'
import {Navbar, Nav, Container, NavDropdown} from 'react-bootstrap'
import { logout } from '../actions/userActions'
import SearchBox  from './SearchBox'
import { getUserDetails } from '../actions/userActions'
import { useNavigate } from "react-router-dom"
import { debounce } from '../utilities/helpers';
// import { CART_REMOVE_ALL_ITEMS }  from '../constants/cartConstants'
import Logo from './Logo'
import logoPic2 from './logoPic2.png'
import './Navbar.css'
import {useMediaQuery, useMediaQueries} from '@react-hook/media-query'
import { isExpired, decodeToken } from "react-jwt";
import secureLocalStorage from 'react-secure-storage'
import jwt_decode from "jwt-decode";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


const Header = ({ match }) => {

   const navigate = useNavigate();

  

    const dispatch = useDispatch()

 
    const matches = useMediaQuery('only screen and (max-width: 830px)')
  

    const userDetails = useSelector(state => state.userDetails)
    const { success, user} = userDetails

    


 

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    
 

      useEffect(() => {
      
            if (userInfo) {
                dispatch(getUserDetails(userInfo._id))
            } else{
               
               
            }

           
        
    }, [dispatch, navigate, userInfo, success,])





    const logoutHandler = () => {
         navigate('/login') 
        dispatch(logout())
       
        window.localStorage.clear(); //clear all localstorage
       
        
    }

    //LOG OUT IF TOKEN IS EXPIRED USING JWT_DECODE
     const decodedToken = userInfo && jwt_decode(userInfo.token);

     useEffect(() => {

       const currentDate = new Date()

       const deToken = decodedToken && decodedToken.exp * 1000 < currentDate.getTime()

     // JWT exp is in seconds
        if (deToken) {

             dispatch(logout())

            return navigate('/login')
       
        } else {
            //   console.log("Valid token");   
       }
         
     },[navigate, decodedToken, dispatch])
     

 
   



    return (
        // <header  style={{backgroundColor: '#232F3E', justifyContent: 'center', color: '#ffffff', marginBottom: !matches ? 139 : 20, top: 0, left: 0, height:'20%', border: '1px solid rgba(255, 255, 255)', }}>

    <Navbar 
        fixed={!matches ? 'top' : 'none'} 
        // sticky={!matches ? 'top' : 'none'}
        expand="lg"  
        style={{
            opacity: '100%',
            margin: -2,
            marginLeft: -20,
            marginRight: -20,
            padding: 0,
            backgroundColor: '#232F3E', 
            // justifyContent: 'center', 
            color: '#ffffff', 
            top: 0, 
            left: 0, 
            height: '8%', 
            // border: '1px solid rgba(255, 255, 255)', 
        }}
        >
 
      <Container style={{ backgroundColor: '#232F3E', color: '#ffffff', height: '8%', paddingLeft: 8, paddingRight: 10,}} >

       { userInfo && !isMobile ?  
       
            //  <Navbar.Brand href="/admin/videoCarousel" ><Logo /></Navbar.Brand> 
            <Navbar.Brand href="/admin/videoCarousel" ><img alt="" src={logoPic2}  width={65} height={65} /></Navbar.Brand>

                : 

        userInfo && isMobile ?  
       
            //  <Navbar.Brand href="/admin/videoCarousel" ><Logo /></Navbar.Brand> 
            <Navbar.Brand href="/admin/displayAllVideoScreen" ><img alt="" src={logoPic2}  width={65} height={65} /></Navbar.Brand>

                : 
                
            // <Navbar.Brand href="/login" ><Logo /></Navbar.Brand> 
             <Navbar.Brand href="/login" ><img alt="" src={logoPic2}  width={65} height={65} /></Navbar.Brand> 
        
        }

        <p style={{color: '#ffffff', backgroundColor: '#20c5b5', borderRadius: 30, padding: 5, marginRight: 10, marginTop: 8}}>Beta</p>
            
        <Navbar.Toggle style={{color:"#ffffff", borderColor: '#ffffff', borderWidth: 2, borderStyle: 'solid', borderRadius: 5, padding: 4, marginRight: 10 }} aria-controls="basic-navbar-nav" ><i className="fa fa-bars"  ></i></Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav"  style={{marginLeft: 20}}
>
          <Nav className="me-auto">
             { 
                userInfo ? 
                    <>
                            
             
                            {!isMobile && <Nav.Link href="/admin/videoCarousel" style={{color:"#ffffff"}}>Home</Nav.Link>}                       
                            <Nav.Link href="/admin/displayAllVideoScreen" style={{color:"#ffffff"}}> Video-Gallery</Nav.Link>
                            <Nav.Link href="/admin/VideoCreateScreen" style={{color:"#ffffff"}}> Create</Nav.Link>
                    
                            
                    </>

                    :

                    <>
                        <Nav.Link href="/login" style={{color:"#ffffff"}}>Home</Nav.Link>
                        <Nav.Link href="/login" style={{color:"#ffffff"}}> Video-Gallery</Nav.Link>
                        <Nav.Link href="/login" style={{color:"#ffffff"}}> Create</Nav.Link>
               
                    </>

             }

             

             <Nav  className="me-auto" >
                       {userInfo && 
                            <NavDropdown title="Profile" id="basic-nav-dropdown" style={{color:"#ffffff"}}>
                                <NavDropdown.Item href="/admin/profileScreen" style={{color:"#232F3E", fontWeight:'bold',  textTransform: 'uppercase'}}>My-Profile</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={ logoutHandler } style={{color:"#232F3E", fontWeight:'bold',  textTransform: 'uppercase'}}>
                                Logout
                                </NavDropdown.Item>
                            </NavDropdown>
                        }
                            
                        {userInfo &&                                                      
                            <NavDropdown title="Admin" id="basic-nav-dropdown" style={{color:"#ffffff"}}>                         
                                <NavDropdown.Item href="/admin/videoList" style={{color:"#232F3E", fontWeight:'bold',  textTransform: 'uppercase'}}>
                                    My-Video-List
                                </NavDropdown.Item>

                                { userInfo.isSeller || userInfo.isAdmin ? 
                                
                                <>

                                <NavDropdown.Divider />

                                <NavDropdown.Item href="/admin/markedVideos" style={{color:"#232F3E", fontWeight:'bold', textTransform: 'uppercase'}}>
                                    Saved-Videos
                                </NavDropdown.Item>

                                </>

                                :

                                null

                                }
                                 {/* {userInfo && userInfo.isSeller === true && userInfo.isAdmin === false && <>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/admin/markedVideos" style={{color:"#232F3E", fontWeight:'bold',  textTransform: 'uppercase'}}>
                                    Saved-Videos
                                    </NavDropdown.Item>
                                </>
                                } */}
                              
                            </NavDropdown>
                        }
                            
             </Nav>
             

           
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

           
            
        // </header>
    )
}

export default Header
