import React, { useState } from 'react'
import { Form, Button, } from 'react-bootstrap'
import { useNavigate } from "react-router-dom"

const SearchBox = (videoCreatorId) => {
  
  const [keyword, setKeyword] = useState('')

  // console.log('SEARCH ID', videoCreatorId.videoCreatorId)

  

     const navigate = useNavigate();

  const submitHandler = (e) => {
    e.preventDefault()
    if (keyword.trim()) {
      if(videoCreatorId.videoCreatorId !== undefined){
          navigate(`/admin/profileScreen/search/?videoCreatorId=${videoCreatorId.videoCreatorId}&keyword=${keyword}`)
      } else {
          navigate(`/admin/profileScreen/search/?keyword=${keyword}`)
      }
    
    } else {
      navigate('/admin/profileScreen')
    }
  }


  return (
      <div  className="d-flex justify-content-center" style={{marginTop: 2, marginBottom: 2, width: '85%'}}>
      <Form onSubmit={submitHandler}  className="d-flex justify-content-center" style={{width: '30rem'}} > 
     
      <Form.Control
        
        style={{boxShadow: 'none', fontSize: '16px', width: '100%', backgroundColor: '', borderColor: '#cccccc', borderWidth: 2, borderTopLeftRadius: 15, borderBottomLeftRadius: 15, paddingTop: 5, paddingBottom: 5}}
        type='text'
        name='q'
        onChange={(e) => setKeyword(e.target.value)}
        placeholder='search...'
        className='mr-sm-2 ml-sm-2 text-center m-2'
        
      ></Form.Control>
      <Button type='submit' 
      //variant='success' 
      style={{boxShadow: 'none', backgroundColor:'#20c5b5' , color: '#ffffff', height: '3.7em', borderTopRightRadius:15, borderBottomRightRadius:15,}}   
      className=' m-auto'
      >
        Search
      </Button>
     
    </Form>
    </div>
   
  )
}

export default SearchBox