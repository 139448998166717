import React, {useEffect, useState} from 'react'
import Pagination from 'react-bootstrap/Pagination';
import { LinkContainer } from 'react-router-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
// import { scrollToTop } from "./scrollHelpers";
import PropTypes from "prop-types";


const PaginateVideoList = ({ itemsCount, pages, page, isAdmin = false, keyword = '' }) => {

    const navigate = useNavigate();

 


      const [sessionsPerPage, setSessionsPerPage] = useState(12);
      const [currentPage, setCurrentPage] = useState(1);
      // const scrollPosition = useScroll();

      console.log('CURRENT PAGE ', currentPage)

      const itemsPerPage = page

      const alwaysShown = true

      const pagesCount = pages
      const isPaginationShown = alwaysShown ? true : pagesCount > 1;
      const isCurrentPageFirst = currentPage === 1;
      const isCurrentPageLast = currentPage === pagesCount;

     

      const changePage = number => {
        if (currentPage === number) return;
    

       setCurrentPage(number);
          
      //  <Link key={number}
      //             to={
      //               !isAdmin
      //                 ? keyword
      //                   ? `/admin/videoList/search/${keyword}/page/${number}`
      //                   : `/admin/videoList/page/${number}`
      //                 : `/admin/videoList/${number}`
      //             }
      //     >
      //     </Link>

           
          
                
      //   scrollToTop();
      };


      const  onPageNumberClick = pageNumber => { 
        
                   

                        // if (keyword) {
                        //  navigate(`/admin/videoList/search/${keyword}/page/${pageNumber}`)
                        // } 
                        //  if (!keyword && page) {
                        //   navigate(`/admin/videoList/page/${pageNumber}`)
                        // } 
                        
                        // if (!page && pages) {
                        //  navigate(`/admin/videoList/${pageNumber}`)
                        // }
                       
                    
                      
        
                        
                    
               
       

          changePage(pageNumber);
        

      };
     

      //  useEffect(() => {

      //   onPageNumberClick()

      //   })

      const onPreviousPageClick = () => {
        
         <Link key={currentPage => currentPage - 1}
                  to={
                    !isAdmin
                      ? keyword
                        ? `/admin/videoList/search/${keyword}/page/${currentPage => currentPage - 1}`
                        : `/admin/videoList/page/${currentPage => currentPage - 1}`
                      : `/admin/videoList/${currentPage => currentPage - 1}`
                  }
          >
          </Link>

          changePage(currentPage => currentPage - 1);
      };

      const onNextPageClick = () => {
        
        <Link key={currentPage => currentPage + 1}
                  to={
                    !isAdmin
                      ? keyword
                        ? `/admin/videoList/search/${keyword}/page/${currentPage => currentPage + 1}`
                        : `/admin/videoList/page/${currentPage => currentPage + 1}`
                      : `/admin/videoList/${currentPage => currentPage + 1}`
                  }
          >
          </Link>

          changePage(currentPage => currentPage + 1);
      };

      const setLastPageAsCurrent = () => {
        if (currentPage > pagesCount) {
          setCurrentPage(pagesCount);

        }
      };

      let isPageNumberOutOfRange;

      const pageNumbers = [...new Array(pagesCount)].map((_, index) => {
        const pageNumber = index + 1;
        const isPageNumberFirst = pageNumber === 1;
        const isPageNumberLast = pageNumber === pagesCount;
        const isCurrentPageWithinTwoPageNumbers =
          Math.abs(pageNumber - currentPage) <= 2;

        if (
          isPageNumberFirst ||
          isPageNumberLast ||
          isCurrentPageWithinTwoPageNumbers
        ) {
          isPageNumberOutOfRange = false;
          return (
            <LinkContainer        
                  key={pageNumber}
                  to={
                    !isAdmin
                      ? keyword
                        ? `/admin/videoList/search/${keyword}/page/${pageNumber}`
                        : `/admin/videoList/page/${pageNumber}`
                      : `/admin/videoList/${pageNumber}`
                  }
            >
                <Pagination.Item
                  key={pageNumber}
                  onClick={() => onPageNumberClick(pageNumber)}
                  active={pageNumber === currentPage}
                  activeLabel = ""
                  
                >
                  {pageNumber}
                </Pagination.Item>
           </LinkContainer>
          );
        }

        if (!isPageNumberOutOfRange) {
          isPageNumberOutOfRange = true;
          return <Pagination.Ellipsis key={pageNumber} className="muted" />;
        }

        return null;
      });

      useEffect(setLastPageAsCurrent, [pagesCount, currentPage]);



  return (
    // pages > 1 && (
    //   <Pagination>
    //     {[...Array(pages).keys()].map((x) => (
    //       <LinkContainer
    //         key={x + 1}
    //         to={
    //           !isAdmin
    //             ? keyword
    //               ? `/admin/videoList/search/${keyword}/page/${x + 1}`
    //               : `/admin/videoList/page/${x + 1}`
    //             : `/admin/videoList/${x + 1}`
    //         }
    //       >
    //         <Pagination.Item active={x + 1 === page} activeLabel = "">{x + 1}</Pagination.Item>
    //       </LinkContainer>
    //     ))}
    //   </Pagination>
    // )

    <>
      {isPaginationShown && (
        <Pagination>
          <Pagination.Prev
            onClick={onPreviousPageClick}
            disabled={isCurrentPageFirst}
          />
          {pageNumbers}
          <Pagination.Next
            onClick={onNextPageClick}
            disabled={isCurrentPageLast}
          />
        </Pagination>
      )}
    </>
  )
}

export default PaginateVideoList