
import axios from 'axios'
import toast from 'react-simple-toasts';
import {

  GET_ERRORS,
  SET_CURRENT_USER ,
  ADD_VIDEO,
  VIDEO_LOADING,
  GET_VIDEOS,
  GET_MYVIDEOS,
  ADD_PIC,
  GET_PICS,
  GET_PIC,
  PIC_LOADING,
  UPDATE_LIKES,
  VIDEO_ERROR,
  CONTENT_ADD_VIDEO_REQUEST,
  CONTENT_ADD_VIDEO_SUCCESS,
  CONTENT_ADD_VIDEO_FAIL,
  CONTENT_VIDEO_REQUEST,
  CONTENT_VIDEO_SUCCESS,
  CONTENT_VIDEO_FAIL,
  MY_VIDEO_LIST_REQUEST,
  MY_VIDEO_LIST_SUCCESS,
  MY_VIDEO_LIST_FAIL,
  CONTENT_ADD_IMAGE_REQUEST,
  CONTENT_ADD_IMAGE_SUCCESS,
  CONTENT_ADD_IMAGE_FAIL,
  MY_IMAGE_LIST_REQUEST,
  MY_IMAGE_LIST_SUCCESS,
  MY_IMAGE_LIST_FAIL,
  ALL_VIDEO_LIST_REQUEST,
  ALL_VIDEO_LIST_SUCCESS,
  ALL_VIDEO_LIST_FAIL,
  COMMENT_CREATE_FAIL,
  COMMENT_CREATE_SUCCESS,
  COMMENT_CREATE_REQUEST,
  CONTENT_LIKE_SUCCESS,
  CONTENT_VIDEO_DETAILS_SUCCESS,
  CONTENT_LIKE_FAIL,
  CONTENT_LIKE_REQUEST,
  CONTENT_DELETE_SUCCESS,
  CONTENT_DELETE_REQUEST,
  CONTENT_DELETE_FAIL,
  COMPANY_VIDEO_LIST_REQUEST,
  COMPANY_VIDEO_LIST_SUCCESS,
  COMPANY_VIDEO_LIST_FAIL,
  CONTENT_MARKED_DELETE_REQUEST,
  CONTENT_MARKED_DELETE_SUCCESS,
  CONTENT_MARKED_DELETE_FAIL,
  CONTENT_TOPUBLIC_REQUEST,
  CONTENT_TOPUBLIC_SUCCESS,
  CONTENT_TOPUBLIC_FAIL,
  CONTENT_SHARE_REQUEST,
  CONTENT_SHARE_SUCCESS,
  CONTENT_SHARE_FAIL,
  CONTENT_MARKED_SHARE_REQUEST,
  CONTENT_MARKED_SHARE_SUCCESS,
  CONTENT_MARKED_SHARE_FAIL,
  // CONTENT_DETAILS_SUCCESS



} from '../constants/contentConstants'

import { URL } from '../App';






//////////////////////   VIDEO ACTIONS    ///////////////////////////////////


//Add Video action
export const addVideoContent = (contentData) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: CONTENT_ADD_VIDEO_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

// console.log('VIDEO USERINFO ',userInfo)

    //  const config = {
    //                 headers: {
    //                   'Content-Type': 'application/json',
    //                   authorization: `Bearer ${userInfo.token}`,
    //                 },
    //               }

    const email = userInfo.email

    const contentInfo = Object.assign({email: email}, contentData)

    console.log('CONTENT DATA CONFIG', contentInfo)


    // const  {data}  =  await axios.post(`${URL}/api/content/addContent/videoContent`, contentInfo, config )

        const  {data}  = await axios({
                          method: 'post',
                          mode: 'cors',    
                          url: `${URL}/api/content/addContent/videoContent`,                                           
                          headers: {
                                  'Content-Type': 'application/json',
                                  authorization: `Bearer ${userInfo.token}`,
                                  },
                          data: contentInfo,  
                        })

    console.log('CONTENT DATA FROM AXIOS', data)

    dispatch({
      type: CONTENT_ADD_VIDEO_SUCCESS,
      payload: data
    })

      toast('Video uploaded successfully!',{
                    time: 4000,
                    position: 'top-center',
                    clickable: true,
                    clickClosable: true,
                    className: 'custom-toast',
                    render: message => <div style={{zIndex: 1001, paddingRight: 4, paddingLeft: 4, borderRadius: 30, fontSize: 18, textAlign: 'center', justifyContent: 'center', fontWeight: '400', marginTop: '10rem', marginBottom: '4rem', color: '#ffffff', padding: 4, backgroundColor: 'green', zIndex: 1, opacity: 1}}>'Video uploaded successfully!'</div>

                    })
                    
  } catch (error) {

      //  console.log('VIDEO LOADING ERROR', error)

          if(!error) {
            
                } else {
                  toast('Video Failed to Upload', {
                  time: 4000,
                  position: 'top-center',
                  clickable: true,
                  clickClosable: true,
                  className: 'custom-toast',
                  render: message => <div style={{paddingRight: 4, paddingLeft: 4, borderRadius: 30, fontSize: 18, textAlign: 'center', justifyContent: 'center', fontWeight: '400', marginTop: '10rem', marginBottom: '4rem', color: '#ffffff', padding: 4, backgroundColor: 'red', zIndex: 1, opacity: 1}}>Video Failed to Upload!</div>,
                  })
                }

    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      // dispatch(logout())
    }
    dispatch({
      type: CONTENT_ADD_VIDEO_FAIL,
      payload: message,
    })
  }
}



//Get all my videos
  export  const getMyVideoContent = (id, keyword = '', pageNumber = '') => async (dispatch, getState) => {

        try {
            dispatch({
                type: MY_VIDEO_LIST_REQUEST
            })

              const {
                  userLogin: { userInfo }
                } = getState()

            
                 const config = {
                    headers: {
                      authorization: `Bearer ${userInfo.token}`,
                    },
                  }

                  //  console.log('CONTENT KEYWORD/PAGE', keyword, pageNumber  )

                  //  console.log('CONTENTID', id  )

                  

          //  `${URL}/api/products?keyword=${keyword}&pageNumber=${pageNumber}`
          const { data } = await axios.get(`${URL}/api/content/myVideos/${id}?keyword=${keyword}&pageNumber=${pageNumber}`, config)

          // console.log('CONTENT VIDEO LIST ', data)

            dispatch({
                type: MY_VIDEO_LIST_SUCCESS,
                payload: data
            })
        } catch(error){
            const message =
                error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message

              //  console.log('ERROR VIDEOS',message)
              if (message === 'Not authorized, token failed') {
                // dispatch(logout())
               
              }
              console.log(error)
              dispatch({
                type: MY_VIDEO_LIST_FAIL,
                payload: message,
              })
        }
    }

    


    //Get all my videos
  export  const getCompanyVideoContent = (companyName, keyword = '', pageNumber = '') => async (dispatch, getState) => {

    // console.log('ACTIONS',companyName)

        try {
            dispatch({
                type:COMPANY_VIDEO_LIST_REQUEST
            })

              const {
                  userLogin: { userInfo }
                } = getState()

            
                 const config = {
                    headers: {
                      authorization: `Bearer ${userInfo.token}`,
                    },
                  }

                   //console.log('CONTENT KEYWORD/PAGE', keyword, pageNumber  )

          //  `${URL}/api/products?keyword=${keyword}&pageNumber=${pageNumber}`
          const { data } = await axios.get(`${URL}/api/content/companyRatedVideo/${companyName}?keyword=${keyword}&pageNumber=${pageNumber}`, config)

          //console.log('CONTENT VIDEO LIST ', data)

            dispatch({
                type:COMPANY_VIDEO_LIST_SUCCESS,
                payload: data
            })
        } catch(error){
            const message =
                error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message

               console.log('ERROR VIDEOS',message)
              if (message === 'Not authorized, token failed') {
                // dispatch(logout())
               
              }
              console.log(error)
              dispatch({
                type:COMPANY_VIDEO_LIST_FAIL,
                payload: message,
              })
        }
    }

    //Get all my videos
  export  const getAllVideoContent = ( keyword = '', pageNumber = '') => async (dispatch, getState) => {

      // console.log('CONTENT KEYWORD/PAGE', keyword )

        try {
            dispatch({
                type:  ALL_VIDEO_LIST_REQUEST
            })


               const {
                  userLogin: { userInfo }
                } = getState()

            
                 const config = {
                    headers: {
                      authorization: `Bearer ${userInfo.token}`,
                    },
                  }

                  

          //  `${URL}/api/products?keyword=${keyword}&pageNumber=${pageNumber}`
          const { data } = await axios.get(`${URL}/api/content/allVideos?keyword=${keyword}&pageNumber=${pageNumber}`, config)

          // console.log('ALL CONTENT VIDEO LIST ', data)

            dispatch({
                type: ALL_VIDEO_LIST_SUCCESS,
                payload: data
            })
        } catch(error){
            dispatch({
                type: ALL_VIDEO_LIST_FAIL,
                payload: error.response && error.response.data.message ? 
                error.response.data.message
                : error.message
            })
        }
    }


    //Get all my videos
  export  const getAllCarouselVideoContent = ( keyword = '') => async (dispatch, getState) => {

      //  console.log('CONTENT KEYWORD', keyword )

        try {
            dispatch({
                type:  ALL_VIDEO_LIST_REQUEST
            })

               const {
                  userLogin: { userInfo }
                } = getState()

            
                 const config = {
                    headers: {
                      authorization: `Bearer ${userInfo.token}`,
                    },
                  }


                  

          //  `${URL}/api/products?keyword=${keyword}&pageNumber=${pageNumber}`
          const { data } = await axios.get(`${URL}/api/content/allCarouselVideos?keyword=${keyword}`, config)

          // console.log('ALL CONTENT VIDEO LIST ', data)

            dispatch({
                type: ALL_VIDEO_LIST_SUCCESS,
                payload: data
            })
        } catch(error){
            dispatch({
                type: ALL_VIDEO_LIST_FAIL,
                payload: error.response && error.response.data.message ? 
                error.response.data.message
                : error.message
            })
        }
    }


    //Add Image Action
export const addImageContent = (contentData) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: CONTENT_ADD_IMAGE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

// console.log('CONTENT DATA CLOUDINARY IMAGE',contentData)

     const config = {
                    headers: {
                      'Content-Type': 'application/json',
                      authorization: `Bearer ${userInfo.token}`,
                    },
                  }



    const contentInfo = Object.assign(userInfo, contentData)

    // console.log('CONTENT DATA CONFIG', contentInfo)


    const  {data}  =  await axios.post(`${URL}/api/content/addContent/imageContent`, contentInfo, config )

    // console.log('CONTENT DATA FROM CLOUDINARY IMAGE', data)

    dispatch({
      type: CONTENT_ADD_IMAGE_SUCCESS,
      payload: data
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      // dispatch(logout())
    }
    dispatch({
      type: CONTENT_ADD_IMAGE_FAIL,
      payload: message,
    })
  }
}


//Get all my IMAGEs
  export const getMyImageContent = (id, keyword = '', pageNumber = '') => async (dispatch, getState) => {

    console.log('ID ID', id)

        try {
            dispatch({
                type: MY_IMAGE_LIST_REQUEST
            })

              const {
                  userLogin: { userInfo }
                } = getState()

            
                 const config = {
                    headers: {
                      authorization: `Bearer ${userInfo.token}`,
                    },
                  }

                  //  console.log('CONTENT ID USER ', id)

          
          const { data } = await axios.get(`${URL}/api/content/myImages/${id}?keyword=${keyword}&pageNumber=${pageNumber}`, config)

          // console.log('CONTENT IMAGE LIST ', data)

            dispatch({
                type: MY_IMAGE_LIST_SUCCESS,
                payload: data
            })
        } catch(error){
            const message =
                error.response && error.response.data.message
                  ? error.response.data.message
                  : error.message
              if (message === 'Not authorized, token failed') {
               
              }
              console.log(error)
              dispatch({
                type: MY_IMAGE_LIST_FAIL,
                payload: message,
              })
        }
    }

  
export const deleteContent = (id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: CONTENT_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`${URL}/api/content/${id}`, config)

    dispatch({
      type: CONTENT_DELETE_SUCCESS,
    }) && toast(`Video Successfully deleted`, {
      time: 4000,
      position: 'top-center',
      clickable: true,
      clickClosable: true,
      className: 'custom-toast',
      render: message => <div style={{paddingRight: 8, paddingLeft: 8, borderRadius: 30, fontSize: 18, textAlign: 'center', justifyContent: 'center', fontWeight: '400', marginTop: '30em', marginBottom: '4rem', color: '#ffffff', padding: 8, backgroundColor: 'green', zIndex: 1, opacity: 1}}>Video successfully deleted</div>,
    })  
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      // dispatch(logout())
    }
    dispatch({
      type: CONTENT_DELETE_FAIL,
      payload: message,
    })
  }
}

export const deleteMarkedVideoContent = (id) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: CONTENT_MARKED_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${userInfo.token}`,
      },
    }

    // console.log('MARKED VIDEO ACTION')

    await axios.delete(`${URL}/api/content/markedsaved/${id}`,config)


                                                          

    dispatch({
      type: CONTENT_MARKED_DELETE_SUCCESS,
    }) && toast(`Video Successfully deleted, refresh page after finishing all deleting `, {
      time: 4000,
      position: 'top-center',
      clickable: true,
      clickClosable: true,
      className: 'custom-toast',
      render: message => <div style={{paddingRight: 8, paddingLeft: 8, borderRadius: 30, fontSize: 18, textAlign: 'center', justifyContent: 'center', fontWeight: '400', marginTop: '30em', marginBottom: '4rem', color: '#ffffff', padding: 8, backgroundColor: 'green', zIndex: 1, opacity: 1}}>Video successfully deleted, refresh page after finishing all deleting</div>,
    })  

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      // dispatch(logout())
    }
    dispatch({
      type: CONTENT_MARKED_DELETE_FAIL,
      payload: message,
    })
  }
}



// Add Like
export const addLike = (id) => async (dispatch, getState) => {

// console.log('LIKE ACTION ID', id)

  try {
      
    dispatch({ type: CONTENT_LIKE_REQUEST })

    const {
          userLogin: { userInfo },
        } = getState()


      // console.log('USERINFO LIKE', userInfo.token)



    const url =  `${URL}/api/content/like/${id}`

          const { data } =  axios({ method: 'put', 
                  url: url, 
                  headers: {
                      'Content-Type': 'application/json',
                      authorization: `Bearer ${userInfo.token}`,
                    },
                })



     

      dispatch({
      type: CONTENT_LIKE_SUCCESS,
      payload: data,
    })

      dispatch({
      type: CONTENT_VIDEO_DETAILS_SUCCESS,
      payload: data,
    })
 
  }
  catch (error) {

    //  console.log('ERROR', error)
  
    dispatch({type: CONTENT_LIKE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
        })
  }
};



export const removeLike = (id) => async (dispatch, getState) => {

  try {
      
    dispatch({ type: CONTENT_LIKE_REQUEST })

    const {
        userLogin: { userInfo },
      } = getState()


      //  const config = {
      //     headers: {
          
      //       'Content-Type': 'application/json',  
      //       authorization: `Bearer ${userInfo.token}`,
      //     },
      //   }


        const url =  `${URL}/api/content/unLike/${id}`

            const { data } =  axios({ method: 'put', 
                  url: url, 
                  headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${userInfo.token}`,
                  },
                })


       

      // console.log('UNLIKE ACTION',data)

     

      dispatch({
      type: CONTENT_LIKE_SUCCESS,
      payload: data,
    })

   
   
  }
  catch (error) {

    // console.log('UNLIKE',error)
     
    dispatch({type: CONTENT_LIKE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
        })
  }
}





export const setVideoLoading = () => {
  return {
    type: VIDEO_LOADING
  }
}

//////////////////////   IMAGE ACTIONS    ///////////////////////////////////

//Add pic
export const addPic = (picData) => dispatch => {
dispatch(setPicLoading());
  axios
  .post(`${URL}/api/upload/files/profilePic`, picData)
  .then( res =>
    //console.log("RESPONSE",res)
    dispatch({
      type: ADD_PIC,
      payload: res.data
    })
  )
  .catch(err =>
  dispatch({
    type: GET_ERRORS,
    payload: err.response.data
  })

)


}


// Get all images
export const getPics = () => dispatch => {
    dispatch(setPicLoading());
  axios
    .get(`${URL}/api/upload/allpics`)
    .then(res =>
      dispatch({
        type: GET_PICS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_PICS,
        payload: null
      })
    );
};

// Get all profiles
export const getMyPics = () => dispatch => {

  axios
    .get(`${URL}/api/upload/mypics`)
    .then(res =>
      dispatch({
        type: GET_PICS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_PICS,
        payload: null
      })
    );
};


// Get profile by handle
export const getPicById = () => dispatch => {

  axios
    .get(`${URL}/api/upload/user/:user_id`)
    .then(res =>
      dispatch({
        type: GET_PIC,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_PIC,
        payload: null
      })
    );
};


export const createComment = (itemId, comment, commentRate) => async (
  dispatch,
  getState
) => {



// console.log('ACTION COMMENT', comment, itemId)

//  const commentData = { 'comment': comment } 

  try {

    dispatch({
      type:COMMENT_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo }
    } = getState()


    const config = {
      headers: {
      
        'Content-Type': 'application/json',  
        authorization: `Bearer ${userInfo.token}`,
      },
    }

    //  console.log('ACTION COMMENT URL', commentData)

    //  console.log('ACTION COMMENT HEADERS',  config)

    const commentInfo = Object.assign({comment: comment}, commentRate)

      // console.log('ACTION COMMENT BODY', commentInfo)

    const {data} = await axios.put(`${URL}/api/content/comments/${itemId}/`, commentInfo, config)
    
    
     

      //  console.log('ACTION COMMENT AXIOS', data)

          // const url =  `${URL}/api/content/${itemId}/comments`

          // console.log('ACTION COMMENT URL',  url, commentData)

          // const { data } =  await axios({ method: 'put', 
          //         url: url, 
          //         data: commentData,  
          //         headers: {
          //           'Content-Type': 'application/json',  
          //           'Access-Control-Allow-Origin': '*',  
          //           'Access-Control-Allow-Methods': '*', 
          //           authorization: `Bearer ${userInfo.token}`,
          //         },
          //       })
      


 

    dispatch({
      type:COMMENT_CREATE_SUCCESS,
    })

    dispatch({
      type: CONTENT_VIDEO_DETAILS_SUCCESS,
      payload: data,
    })


        if(data !== undefined){
                 toast('Comment added successfully',{
                    time: 5000,
                    position: 'middle-center',
                    clickable: true,
                    clickClosable: true,
                    className: 'custom-toast',
                    render: message => <div style={{paddingRight: 4, paddingLeft: 4, borderRadius: 30, fontSize: 18, textAlign: 'center', justifyContent: 'center', fontWeight: '400', marginTop: '10rem', marginBottom: '4rem', color: '#ffffff', padding: 4, backgroundColor: 'green', zIndex: 1, opacity: 1}}>Comment added successfully</div>

                    })
            }
 

  } catch (error) {

  
    // alert(error)

    // alert(error.response)

    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    // alert(message)
    
    dispatch({
      type:COMMENT_CREATE_FAIL,
      payload: message,
    })
  }
}


export const createCommentSavedVideo = (itemIdAllContent, itemIdSavedVideo, comment, commentRate) => async (
  dispatch,
  getState
) => {

  // (itemId)

// console.log('ACTION COMMENT', comment)

//  const commentData = { 'comment': comment } 

  try {
    dispatch({
      type:COMMENT_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${userInfo.token}`,
      },
    }

      const commentInfo = Object.assign({comment: comment}, commentRate)

    const { data } = await axios.put(`${URL}/api/content/comments/?itemIdAllContent=${itemIdAllContent}&itemIdSavedVideo=${itemIdSavedVideo}`, commentInfo, config)


        // const url =  `${URL}/api/content/comments/?itemIdAllContent=${itemIdAllContent}&itemIdSavedVideo=${itemIdSavedVideo}`

       
        //   const { data } =  await axios({ method: 'put', 
        //           url: url, 
        //           comment,  
        //           headers: {
        //               'Content-Type': 'application/json',
        //               authorization: `Bearer ${userInfo.token}`,
        //             },
        //         })
      

    dispatch({
      type: COMMENT_CREATE_SUCCESS,
    })

      if(data !== undefined){
                 toast('Comment added successfully',{
                    time: 3000,
                    position: 'top-center',
                    clickable: true,
                    clickClosable: true,
                    className: 'custom-toast',
                    render: message => <div style={{paddingRight: 4, paddingLeft: 4, borderRadius: 30, fontSize: 18, textAlign: 'center', justifyContent: 'center', fontWeight: '400', marginTop: '10rem', marginBottom: '4rem', color: '#ffffff', padding: 4, backgroundColor: 'green', zIndex: 1, opacity: 1}}>Comment added successfully</div>

                    })
            }

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    //  console.log('ERROR', message)
    
    dispatch({
      type:COMMENT_CREATE_FAIL,
      payload: message,
    })


  }
}



// Add Like
export const changeVideotoPublic = (id, selectedStatus) => async (dispatch, getState) => {

// console.log('Video ID', id, selectedStatus)

  try {
      
    dispatch({ type: CONTENT_TOPUBLIC_REQUEST })

    const {
          userLogin: { userInfo },
        } = getState()


      // console.log('USERINFO TOPUBLIC', userInfo.token)

        const selectedStatusData = { selectedStatus : selectedStatus}

    const url =  `${URL}/api/content/videotopublic/${id}`

          const { data } =  await axios({ 
                  method: 'put', 
                  mode: 'cors',    
                  url: url, 
                  headers: {
                      'Content-Type': 'application/json',
                      authorization: `Bearer ${userInfo.token}`,
                    },
                  data: selectedStatusData
                })


    //  console.log('PUBLIC ACTION',data)

     

      dispatch({
      type: CONTENT_TOPUBLIC_SUCCESS,
      payload: data,
    })

      dispatch({
      type: CONTENT_VIDEO_DETAILS_SUCCESS,
      payload: data,
    })
 
  }
  catch (error) {

    //  console.log('ERROR', error)
  
    dispatch({type: CONTENT_TOPUBLIC_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
        })
  }
};




// Profile loading
export const setPicLoading = () => {
  return {
    type: PIC_LOADING
  };
};


    // Add Share
export const addShare = (id) => async (dispatch, getState) => {

// console.log('SHARE ACTION 0', id)

  try {
      
    dispatch({ type: CONTENT_SHARE_REQUEST })

    const {
          userLogin: { userInfo },
        } = getState()

    //   const config = {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${userInfo.token}`,
    //   },
    // }



    const url =  `${URL}/api/content/share/${id}`

          const { data } =  axios({ method: 'put', 
                  url: url, 
                  headers: {
                      'Content-Type': 'application/json',
                      authorization: `Bearer ${userInfo.token}`,
                    },
                })

    // console.log('SHARE ACTION',data)

     

      dispatch({
      type: CONTENT_SHARE_SUCCESS,
      payload: data,
    })

    //   dispatch({
    //   type: CONTENT_DETAILS_SUCCESS,
    //   payload: data,
    // })
 
  }
  catch (error) {
  
    dispatch({type: CONTENT_SHARE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
        })
  }
};


 // Add Share
export const addShareMarked = (id, originalVideoId) => async (dispatch, getState) => {

// console.log('SHARE ACTION 0', id)

  try {
      
    dispatch({ type: CONTENT_MARKED_SHARE_REQUEST })

    const {
          userLogin: { userInfo },
        } = getState()

    //   const config = {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${userInfo.token}`,
    //   },
    // }



    const url =  `${URL}/api/content/sharemarked/?id=${id}&originalVideoId=${originalVideoId}`

          const { data } =  axios({ method: 'put', 
                  url: url, 
                  headers: {
                      'Content-Type': 'application/json',
                      authorization: `Bearer ${userInfo.token}`,
                    },
                })

    // console.log('SHARE ACTION',data)

     

      dispatch({
      type: CONTENT_MARKED_SHARE_SUCCESS,
      payload: data,
    })

    //   dispatch({
    //   type: CONTENT_MARKED_DETAILS_SUCCESS,
    //   payload: data,
    // })
 
  }
  catch (error) {
  
    dispatch({type: CONTENT_MARKED_SHARE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
        })
  }
};










