import { USER_FOLLOW_REQUEST, USER_FOLLOW_SUCCESS, USER_FOLLOW_FAIL, USER_DETAILS_SELLER_REQUEST, USER_DETAILS_SELLER_SUCCESS, USER_DETAILS_SELLER_FAIL, SELLER_CONFIRMATION_FAIL, SELLER_CONFIRMATION_REQUEST, SELLER_CONFIRMATION_SUCCESS, SELLER_REGISTER_REQUEST, USER_DETAILS_FAIL, USER_DETAILS_REQUEST, USER_DETAILS_RESET, USER_DETAILS_SUCCESS, USER_LIST_RESET, USER_LIST_FAIL, USER_LIST_REQUEST, USER_LIST_SUCCESS, USER_LOGIN_FAIL, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGOUT, USER_REGISTER_FAIL, USER_REGISTER_REQUEST, USER_REGISTER_SUCCESS, USER_UPDATE_PROFILE_FAIL, USER_UPDATE_PROFILE_REQUEST, USER_UPDATE_PROFILE_SUCCESS, USER_DELETE_FAIL, USER_DELETE_SUCCESS, USER_DELETE_REQUEST, USER_UPDATE_FAIL, USER_UPDATE_SUCCESS, USER_UPDATE_REQUEST, SELLER_REGISTER_FAIL, SELLER_REGISTER_SUCCESS,
  USER_BLOCK_REQUEST,
    USER_BLOCK_SUCCESS,
    USER_BLOCK_FAIL,
    USER_UNBLOCK_REQUEST,
    USER_UNBLOCK_SUCCESS,
    USER_UNBLOCK_FAIL,
    GET_MAIL_REQUEST,
    GET_MAIL_SUCCESS, 
    GET_MAIL_FAIL,
  PROFILE_PICTURE_REQUEST,
  PROFILE_PICTURE_SUCCESS,
  PROFILE_PICTURE_FAIL,
  PROFILE_PICTURE_RESET,

} from "../constants/userConstants"
import axios from 'axios'
import toast from 'react-simple-toasts';
import { URL } from "../App";
import  secureLocalStorage  from  "react-secure-storage";
import { useNavigate } from "react-router-dom"
// import { logout } from '../actions/userActions'




// import { ORDER_LIST_MY_RESET } from "../constants/orderConstants"



export const login = (email, password ) => async (dispatch) => {

  

    try {
        dispatch({
            type: USER_LOGIN_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        // console.log('LOGIN DATA', email, password )

        const { data } = await axios.post(`${URL}/api/users/login`, {  email, password }, config)

      // console.log('LOGIN DATA 2', data )

       const { _id, yourName, companyName, isAdmin, isSeller, token, location, bio, website, createdAt, blockedContentUser, following, followers } = data;

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        //  console.log('LOGIN DATA ', JSON.stringify(_id, yourName, companyName, isAdmin, isSeller, token, location, bio, website, createdAt, blockedContentUser, following, followers).length)

        secureLocalStorage.setItem('userInfo', JSON.stringify({_id, email, yourName, companyName, isAdmin, isSeller, token, location, bio, website, createdAt, blockedContentUser, }) )



    } catch(error){
        dispatch({
                        type: USER_LOGIN_FAIL,
                        payload: error.response && error.response.data.message ? 
                        // error.response.data.message
                        'Invalid email or password'
                        // : error.message
                        : 'Invalid email or password'
                    })
    }

    
}





export const register = (yourName, companyName, email, password, dateOfBirth, gender) => async (dispatch) => {
    try {
        dispatch({
            type: USER_REGISTER_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        // console.log('DOB', yourName, companyName,  email, password, dateOfBirth, gender )

        

        const { data } = await axios.post(`${URL}/api/users/register`, { yourName, companyName,  email, password, dateOfBirth, gender}, config)

        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data
        })

         dispatch({

            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        secureLocalStorage.setItem('userInfo', JSON.stringify(data) )

    } catch(error){
        dispatch({
                        type: USER_REGISTER_FAIL,
                        payload: error.response && error.response.data.message ? 
                        error.response.data.message
                        : error.message
                    })
    }

}



export const logout = () => (dispatch) => {
        secureLocalStorage.removeItem('userInfo')
        secureLocalStorage.clear()
        dispatch({type: USER_LOGOUT})
        dispatch({type: USER_DETAILS_RESET})
        dispatch({type: USER_LIST_RESET})      
    }









    export const getUserDetails = (id) => async (dispatch, getState) => {
        try {
            dispatch({
                type: USER_DETAILS_REQUEST
            })

            const { userLogin: { userInfo } } = getState()

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${userInfo.token}`
                }
            }

              // console.log('USER ACTION USER-DETAILS', id)

            const { data } = await axios.get(`${URL}/api/users/userDetails/${id}`, config)

            // console.log('USER ACTION USER-DETAILS 1', data)

            dispatch({
                type: USER_DETAILS_SUCCESS,
                payload: data
            })

            

       

        } catch(error){
            dispatch({
                            type: USER_DETAILS_FAIL,
                            payload: error.response && error.response.data.message ? 
                            error.response.data.message
                            : error.message
                        })
        }

    
}



 export const updateUserProfile = (username, biograph, emailAdd, password, locationmap, websit) => async (dispatch, getState) => {

    // console.log('USER ACTION',username, biograph, emailAdd, password, locationmap, websit)

    // const user = {yourName, bio, email, password, location, website}

        try {
            dispatch({
                type: USER_UPDATE_PROFILE_REQUEST
            })

            const { userLogin: { userInfo } } = getState()

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${userInfo.token}`
                }
            }

            const yourName = username
            const bio = biograph
            const email = emailAdd
            const location = locationmap       
            const website = websit

            const { data } = await axios.put(`${URL}/api/users/profile`, { yourName, bio, email, password, location, website },  config)

            // console.log( 'PROFILE EDIT ACTION', data)

            dispatch({
                type: USER_UPDATE_PROFILE_SUCCESS,
                payload: data
            })

            dispatch({
                type: USER_LOGIN_SUCCESS,
                payload: data,
            })

            localStorage.setItem('userInfo', JSON.stringify(data))

            if(data !== undefined){
                 toast('Profile updated successfully',{
                    time: 4000,
                    position: 'top-center',
                    clickable: true,
                    clickClosable: true,
                    className: 'custom-toast',
                    render: message => <div style={{paddingRight: 4, paddingLeft: 4, borderRadius: 30, fontSize: 18, textAlign: 'center', justifyContent: 'center', fontWeight: '400', marginTop: '10rem', marginBottom: '4rem', color: '#ffffff', padding: 4, backgroundColor: 'green', zIndex: 1, opacity: 1}}>Profile updated successfully</div>

                    })
                  
            }

       

        } catch(error){
            dispatch({
                            type: USER_UPDATE_PROFILE_FAIL,
                            payload: error.response && error.response.data.message ? 
                            error.response.data.message
                            : error.message
                        })

          toast('Profile update failed, please make sure you fill in your password',{
                    time: 4000,
                    position: 'top-center',
                    clickable: true,
                    clickClosable: true,
                    className: 'custom-toast',
                    render: message => <div style={{paddingRight: 4, paddingLeft: 4, borderRadius: 30, fontSize: 18, textAlign: 'center', justifyContent: 'center', fontWeight: '400', marginTop: '10rem', marginBottom: '4rem', color: '#ffffff', padding: 4, backgroundColor: 'red', zIndex: 1, opacity: 1}}>Profile update failed, please make sure you fill in your password</div>

                    })
        }

    
}



export const getAllUsers = () => async (dispatch, getState) => {

  // console.log('YES YES')



        try {
            dispatch({
                type: USER_LIST_REQUEST
            })

            const { userLogin: { userInfo } } = getState()

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${userInfo.token}`
                }
            }

            // console.log('USER PROFILE1')

     

            const { data } = await axios.get(`${URL}/api/users/allUsers`, config)

                // console.log('USER PROFILE 2', data)

            dispatch({
                type: USER_LIST_SUCCESS,
                payload: data
            })
            

        } catch(error){
          
            dispatch({
                        type: USER_LIST_FAIL,
                        payload: error.response && error.response.data.message ? 
                        error.response.data.message
                        :
                        error.message
                        })
        }

    
}






 export const listUsers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`${URL}/api/users`, config)

    // console.log('USERS', data)

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: USER_LIST_FAIL,
      payload: message,
    })
  }
}

export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`${URL}/api/users/${id}`, config)

    dispatch({ type: USER_DELETE_SUCCESS })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: USER_DELETE_FAIL,
      payload: message,
    })
  }
}

export const updateUser = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`${URL}/api/users/${user._id}`, user, config)

    dispatch({ type: USER_UPDATE_SUCCESS })

    dispatch({ type: USER_DETAILS_SUCCESS, payload: data })

    dispatch({ type: USER_DETAILS_RESET })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: USER_UPDATE_FAIL,
      payload: message,
    })
  }
}





export const addThumbnail = (thumbnailData ) => async (dispatch, getState) => {

    // console.log('THUMB-NAIL', thumbnailData)


    const {thumbnail_url, id } = thumbnailData

        try {
            dispatch({
                type: PROFILE_PICTURE_REQUEST
            })

            const { userLogin: { userInfo } } = getState()

            // console.log('TOKEN', userInfo.token)

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${userInfo.token}`
                }
            }

            const thumbnailUrl = {'thumbnail_url': thumbnail_url}

            // console.log('USER ACTION THUMB-NAIL', id)

            const { data } = await axios.post(`${URL}/api/users/profilePicture/${id}`, thumbnailUrl, config)

            // console.log('USER ACTION THUMB-NAIL', data)

           

            dispatch({
                type: PROFILE_PICTURE_SUCCESS,
                payload: data
            })


          toast('Profile picture uploaded ',{
                    time: 4000,
                    position: 'top-center',
                    clickable: true,
                    clickClosable: true,
                    className: 'custom-toast',
                    render: message => <div style={{paddingRight: 4, paddingLeft: 4, borderRadius: 30, fontSize: 18, textAlign: 'center', justifyContent: 'center', fontWeight: '400', marginTop: '10rem', marginBottom: '4rem', color: '#ffffff', padding: 4, backgroundColor: 'green', zIndex: 1, opacity: 1}}>Profile picture updated</div>

                    })
       

        } catch(error){
            dispatch({
                            type: PROFILE_PICTURE_FAIL,
                            payload: error.response && error.response.data.message ? 
                            error.response.data.message
                            : error.message
                        })
        }

    
}


export const getAllProfilePictures = () => async (dispatch, getState) => {

  // console.log('YES YES')



        try {
            dispatch({
                type: PROFILE_PICTURE_REQUEST
            })

            const { userLogin: { userInfo } } = getState()

            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${userInfo.token}`
                }
            }

            // console.log('USER PROFILE1')

     

            const { data } = await axios.get(`${URL}/api/users/allProfilePictures`, config)

                // console.log('USER PROFILE 2', data)

            dispatch({
                type: PROFILE_PICTURE_SUCCESS,
                payload: data
            })
            

        } catch(error){
          
            dispatch({
                        type: PROFILE_PICTURE_FAIL,
                        payload: error.response && error.response.data.message ? 
                        error.response.data.message
                        :
                        error.message
                        })
        }

    
}




// Add follow
export const addFollower = (id) => async (dispatch, getState) => {

    console.log('FOLLOWER', id)

  try {
      
    dispatch({ type: USER_FOLLOW_REQUEST })

    const {
          userLogin: { userInfo },
        } = getState()


        // alert(id)



          //   const config = {
          //       headers: {
          //           'Content-Type': 'application/json',
          //           authorization: `Bearer ${userInfo.token}`
          //       }
          //   }

          //  const userId = {userId: userInfo._id}

          //   const { data } = await axios.put(`${URL}/api/users/follow/${id}`, userId, config)

      


            const url =  `${URL}/api/users/follow/${id}`

            const { data } =  axios({ method: 'put', 
                  url: url, 
                  userId: userInfo._id,
                  headers: {
                      'Content-Type': 'application/json',
                      authorization: `Bearer ${userInfo.token}`,
                    },
                })
          

            console.log('USER FOLLOWER DATA', data)

             
      // if(data === undefined) {

      //           toast('Something went wrong!',{
      //               time: 4000,
      //               position: 'top-center',
      //               clickable: true,
      //               clickClosable: true,
      //               className: 'custom-toast',
      //               render: message => <div style={{paddingRight: 4, paddingLeft: 4, borderRadius: 30, fontSize: 18, textAlign: 'center', justifyContent: 'center', fontWeight: '400', marginTop: '10rem', marginBottom: '4rem', color: '#ffffff', padding: 4, backgroundColor: 'green', zIndex: 1, opacity: 1}}>Something went wrong!</div>

      //               })
      // } else {

    dispatch({
      type: USER_FOLLOW_SUCCESS,
      payload: data,
    })

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })

      // }

     
 
  }
  catch (error) {
     console.log(error.response.data.message)
    dispatch({type: USER_FOLLOW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
        })
  }
};

export const removeFollower = (id) => async (dispatch, getState) => {

  try {
      
    dispatch({ type: USER_FOLLOW_REQUEST })

    const {
        userLogin: { userInfo },
      } = getState()


      const url =  `${URL}/api/users/unfollow/${id}`

            const { data } =  axios({ method: 'put', 
                  url: url, 
                  headers: {
                      'Content-Type': 'application/json',
                      authorization: `Bearer ${userInfo.token}`,
                    },
                })

      
 
     

      dispatch({
      type: USER_FOLLOW_SUCCESS,
      payload: data,
    })

   
   
   
  }
  catch (error) {
     
    dispatch({type: USER_FOLLOW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
        })
  }
}


// Block user content
export const blockContentAction = (id) => async (dispatch, getState) => {


  try {
      
    dispatch({ type: USER_BLOCK_REQUEST })

    const {
          userLogin: { userInfo },
        } = getState()


     
      

    const url =  `${URL}/api/users/block/${id}`

          const { data } =  axios({ method: 'put', 
                  url: url, 
                  headers: {
                      'Content-Type': 'application/json',
                      authorization: `Bearer ${userInfo.token}`,
                    },
                })

              //  console.log(data,'User blocked successfully!')
    

              if(data !== undefined){
               
                toast('User blocked successfully!',{
                    time: 4000,
                    position: 'top-center',
                    clickable: true,
                    clickClosable: true,
                    className: 'custom-toast',
                    render: message => <div style={{paddingRight: 4, paddingLeft: 4, borderRadius: 30, fontSize: 18, textAlign: 'center', justifyContent: 'center', fontWeight: '400', marginTop: '10rem', marginBottom: '4rem', color: '#ffffff', padding: 4, backgroundColor: 'green', zIndex: 1, opacity: 1}}>User blocked successfully</div>

                    })
              }
 
     

      dispatch({
      type: USER_BLOCK_SUCCESS,
      payload: data,
    })

 
  }
  catch (error) {

   
  
    dispatch({type: USER_BLOCK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
        })
  }
};



// Block user content
export const unBlockContentAction = (id) => async (dispatch, getState) => {


  try {
      
    dispatch({ type: USER_UNBLOCK_REQUEST })

    const {
          userLogin: { userInfo },
        } = getState()


    

     const url =  `${URL}/api/users/unblock/${id}`

          const { data } =  axios({ method: 'put', 
                  url: url, 
                  headers: {
                      'Content-Type': 'application/json',
                      authorization: `Bearer ${userInfo.token}`,
                    },
                })
        
      
     

      dispatch({
      type: USER_UNBLOCK_SUCCESS,
      payload: data,
    })



          toast('Unblocked user successfully!',{
                    time: 4000,
                    position: 'top-center',
                    clickable: true,
                    clickClosable: true,
                    className: 'custom-toast',
                    render: message => <div style={{paddingRight: 4, paddingLeft: 4, borderRadius: 30, fontSize: 18, textAlign: 'center', justifyContent: 'center', fontWeight: '400', marginTop: '10rem', marginBottom: '4rem', color: '#ffffff', padding: 4, backgroundColor: 'green', zIndex: 1, opacity: 1}}>Unblocked user successfully!</div>

                    })

 
  }
  catch (error) {
  
    dispatch({type: USER_UNBLOCK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
        })
  }
};



// send contact mail
export const registerMail = (mailData) =>  async (
    dispatch,
    getState

) => {

// console.log('MAIL ACTION', mailData)

        try {

             dispatch({
            type: GET_MAIL_REQUEST,
        })

          const {
            userLogin: { userInfo },
            } = getState()

           const config = {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${userInfo.token}`,
            },
            }


            const {data} = await axios.put(`${URL}/api/users/mail`, mailData, config)


        dispatch({
            type: GET_MAIL_SUCCESS,
            payload: data
            })

            if(data) {
                toast('Message sent to Golgii support',{
                    time: 4000,
                    position: 'top-center',
                    clickable: true,
                    clickClosable: true,
                    className: 'custom-toast',
                    render: message => <div style={{paddingRight: 4, paddingLeft: 4, borderRadius: 30, fontSize: 18, textAlign: 'center', justifyContent: 'center', fontWeight: '400', marginTop: '10rem', marginBottom: '4rem', color: '#ffffff', padding: 4, backgroundColor: 'green', zIndex: 1, opacity: 1}}>Message sent to Golgii support</div>

                    })
            }


            
        } catch (err) {
            dispatch({
                type: GET_MAIL_FAIL,
                payload: err.response.data
                })

        }


};




// send contact mail
export const deleteVideoMail = (mailData) =>  async (
    dispatch,
    getState

) => {

// console.log('MAIL ACTION', yourName, email, deleteReason, reviewcompanyname)



        try {

             dispatch({
            type: GET_MAIL_REQUEST,
        })

          const {
            userLogin: { userInfo },
            } = getState()

           const config = {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${userInfo.token}`,
            },
            }


            // const mailData = {
            //   yourName, email, deleteReason, reviewcompanyname
            // }


            const {data} = await axios.put(`${URL}/api/users/deletedvideoMail`, mailData, config)


        dispatch({
            type: GET_MAIL_SUCCESS,
            payload: data
            })

            if(data) {
                toast('Message sent to customer and video status is deleted',{
                    time: 4000,
                    position: 'top-center',
                    clickable: true,
                    clickClosable: true,
                    className: 'custom-toast',
                    render: message => <div style={{paddingRight: 4, paddingLeft: 4, borderRadius: 30, fontSize: 18, textAlign: 'center', justifyContent: 'center', fontWeight: '400', marginTop: '30rem', marginBottom: '4rem', color: '#ffffff', padding: 4, backgroundColor: 'green', zIndex: 1, opacity: 1}}>Message sent to customer and video status is deleted</div>

                    })
            }


            
        } catch (err) {
            dispatch({
                type: GET_MAIL_FAIL,
                payload: err.response.data
                })

        }


};










