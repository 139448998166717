// import React, {useEffect, useState} from 'react'
// import { Container, Row, Col } from 'react-bootstrap'
// import {Link} from 'react-router-dom'
// import { useDispatch, useSelector } from 'react-redux'
// import { Waypoint } from 'react-waypoint'

// const Footer = () => {

//     const [expanded, setExpanded] = useState(false);

//                   const handleEnterViewport = function() {
//                     setExpanded(true);
//                   }
//                   const handleExitViewport = function() {
//                     setExpanded(false);
//                   }


//         const userLogin = useSelector((state) => state.userLogin)
//         const { userInfo } = userLogin

//     return (
            
//         <Waypoint sm={6} xs={12} md={4} lg={4} xl={3} xxl={3}
         
//           // bottomOffset='-2000px'
//           scrollableAncestor={window}                         
//           onEnter={handleEnterViewport}
//           onLeave={handleExitViewport}

//         >
     
//         <footer  
//             style={{
//                 // overflow:'visible',
//                 width: '100vw',  
//                 display: 'block',
//                 position: 'relative', 
//                 border: '1px solid rgba(255, 255, 255)', 
//                 left: 0, 
//                 bottom: 0, 
//                 right: 0
//                 // zIndex: -1,  
//                 // textAlign: 'center',
//                 // paddingTop: 100,
//                 // marginTop:100
//                 }}
//             >
//             <Container  
//                 style={{
                  
//                     backgroundColor: '#232F3E', 
//                     color: '#ffffff', 
//                     //  position: 'fixed', 
//                     height:'10%', 
//                     // paddingTop: 100,
//                     // marginTop: 200
//                     // marginBottom: 50
//                 }} 
//                 fluid 
//             >
//                 <Row style={{justifyContent: 'center', alignItems: 'center', borderTopColor: '#ffffff', borderWidth: 2, padding: 'auto'}}>

//                     <Col className='text-center m-3'   expand="md" >

//                     <p><Link style = {{color: '#ffffff', textDecoration: 'none'}} to= {userInfo ? "/admin/contactUs" : "/login"} >Contact Us</Link></p>

                            
//                     </Col>
//                     <Col className='text-center m-3'   expand="md" >

//                     <p><Link style = {{color: '#ffffff', textDecoration: 'none'}} to="/privacy" >Privacy Policy</Link></p>

                            
//                     </Col>
//                      <Col className='text-center m-2'   expand="md" >

                  

//                     <p><Link style = {{color: '#ffffff', textDecoration: 'none'}} to="/terms" >Terms & Conditions</Link></p>

                   
                            
//                     </Col>
//                      <Col className='text-center m-2'   expand="md" >

                  

//                     <p>Copyright &copy; {new Date().getFullYear()}  Golgii </p>
                            
//                     </Col>
//                 </Row>

//             </Container>
            
//         </footer>

//         </Waypoint>

      
      
//     )
// }




// export default Footer



import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import {Link} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

const Footer = () => {

        const userLogin = useSelector((state) => state.userLogin)
        const { userInfo } = userLogin

    return (
     
        <footer 
            style={{
                opacity: '100%',
                width: '100%',
                border: '1px solid rgba(255, 255, 255)', 
                left: 0, 
                right: 0, 
                bottom: 0, 
                // margin: 0, 
                padding: 0 ,
              
            }} 
                 >
            <Container   
            fluid
                style={{
               
                  margin: 0, padding: 0,
                    backgroundColor: '#232F3E', 
                    color: '#ffffff', 
                    // position: 'relative', 
                    height:'10%', 
                    
           
                }} 
             
            >
                <Row style={{justifyContent: 'center', alignItems: 'center', borderTopColor: '#ffffff', borderWidth: 2, padding: 'auto'}}>

                    <Col className='text-center m-3'   expand="md" >

                    <p><Link style = {{color: '#ffffff', textDecoration: 'none'}} to=  "/aboutUs" >About Golgii</Link></p>

                            
                    </Col>

                    <Col className='text-center m-3'   expand="md" >

                    <p><Link style = {{color: '#ffffff', textDecoration: 'none'}} to= {userInfo ? "/admin/contactUs" : "/login"} >Contact Us</Link></p>

                            
                    </Col>
                    <Col className='text-center m-3'   expand="md" >

                    <p><Link style = {{color: '#ffffff', textDecoration: 'none'}} to="/privacy" >Privacy Policy</Link></p>

                            
                    </Col>
                     <Col className='text-center m-2'   expand="md" >

                  

                    <p><Link style = {{color: '#ffffff', textDecoration: 'none'}} to="/terms" >Terms & Conditions</Link></p>

                   
                            
                    </Col>
                     <Col className='text-center m-2'   expand="md" >

                  

                    <p>Copyright &copy; {new Date().getFullYear()}  Golgii </p>
                            
                    </Col>
                </Row>

            </Container>
            
        </footer>

      
      
    )
}




export default Footer




