import React from 'react';
import {createRoot, ReactDOM}  from 'react-dom/client';
import './index.css';
// import './bootstrap.min.flaty.css'
// import './bootstrap.min.superhero.css'
// import './bootstrap.min.cerulean.css'
// import './bootstrap.min.zephyr.css'
import './bootstrap.min.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider }  from 'react-redux'
import store from './store'
// import { render } from '@testing-library/react'
import {disableReactDevTools} from '@fvilers/disable-react-devtools'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';



if (process.env.NODE_ENV === 'production') {
   disableReactDevTools()
}


// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}


const domNode = document.getElementById("root");

const root = createRoot(domNode);

root.render(
   <Provider store={store}>
     
      <App />
     
  </Provider>
 
);




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();




