import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const AboutUs = () => {
  return (
    <div  style={{ padding: 10, flexDirection: 'row',alignItems: 'center', justifyContent: 'center',  width: '100%'}}>
        
    <Card lg={6} md={6} sm={12} xs={12} border="primary" style={{ width: '99%', margin: 10, marginBottom: 20, borderRadius: 5, justifyContent: 'center', alignContent: 'center', }}>
        <Card.Header style={{fontSize: 18, fontWeight: 'bold', textAlign: 'center'}}>Our Mission</Card.Header>
        <Card.Body>
          <Card.Title>We aim to be Earth's best one stop platform for providing honest, real ratings & reviews for business as well as consumers</Card.Title>

           <hr />

          <Card.Text>
            Its our shared responsibility in this AI era to prevent dishonest and fake information.
          

           <hr />

             Golgii endeavours to provide honest, real ratings & reviews for business and consumers. 

         
            <hr />

            Join us in making earth a better place.


          </Card.Text>
        </Card.Body>
    </Card>
     <Card lg={6} md={6} sm={12} xs={12} border="primary" style={{ width: '99%', margin: 10,  borderRadius: 5 }}>

      <Card.Img variant="top" src="https://res.cloudinary.com/dcjo2dysw/image/upload/v1697635189/Golgii%20Profile%20Pictures%20Signed/ku6dsqhykzxrr3p9m8wj.jpg" style={{width: '20%', alignSelf: 'center'}}/>

       <hr />

      <Card.Body>
        <Card.Title>One act of kindness, honest, giving a real rating & review, a new culture and paradigm shift.</Card.Title>

        <hr />

        <Card.Text>
          Buy a service or a product because you have been given honest and real information. empowering business to make well informed decisions and take responsibility for the information given to consumers.
        </Card.Text>

           <hr />

         <Card.Text>
            Empowering business to make well informed decisions and take responsibility for the information given to consumers.
        </Card.Text>
      
      </Card.Body>
    </Card>
        
    </div>

  )
}

export default AboutUs