export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST "
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS "
export const USER_LOGIN_FAIL= "USER_LOGIN_FAIL "
export const USER_LOGOUT= "USER_LOGOUT "

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST "
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS "
export const USER_REGISTER_FAIL= "USER_REGISTER_FAIL "
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET'

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST "
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS "
export const USER_DETAILS_FAIL= "USER_DETAILS_FAIL "
export const USER_DETAILS_RESET= "USER_DETAILS_RESET"


export const USER_DETAILS_SELLER_REQUEST = "USER_DETAILS_SELLER_REQUEST "
export const USER_DETAILS_SELLER_SUCCESS = "USER_DETAILS_SELLER_SUCCESS "
export const USER_DETAILS_SELLER_FAIL= "USER_DETAILS_SELLER_FAIL "
export const USER_DETAILS_SELLER_RESET= "USER_DETAILS_SELLER_RESET"

export const PROFILE_PICTURE_REQUEST = "PROFILE_PICTURE_REQUEST "
export const PROFILE_PICTURE_SUCCESS = "PROFILE_PICTURE_SUCCESS "
export const PROFILE_PICTURE_FAIL= "PROFILE_PICTURE_FAIL "
export const PROFILE_PICTURE_RESET= "PROFILE_PICTURE_RESET"


export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST "
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS "
export const USER_UPDATE_PROFILE_FAIL= "USER_UPDATE_PROFILE_FAIL "
export const USER_UPDATE_PROFILE_RESET= "USER_UPDATE_PROFILE_RESET "

export const USER_LIST_REQUEST = "USER_LIST_REQUEST "
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS "
export const USER_LIST_FAIL= "USER_LIST_FAIL "
export const USER_LIST_RESET="USER_LIST_RESET"

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST "
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS "
export const USER_DELETE_FAIL= "USER_DELETE_FAIL "

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const SELLER_REGISTER_REQUEST= 'SELLER_REGISTER_REQUEST'
export const SELLER_REGISTER_SUCCESS = 'SELLER_REGISTER_SUCCESS'
export const SELLER_REGISTER_FAIL = 'SELLER_REGISTER_FAIL'
export const SELLER_REGISTER_RESET = 'SELLER_REGISTER_RESET'

export const SELLER_CONFIRMATION_REQUEST= 'SELLER_CONFIRMATION_REQUEST'
export const SELLER_CONFIRMATION_SUCCESS = 'SELLER_CONFIRMATION_SUCCESS'
export const SELLER_CONFIRMATION_FAIL = 'SELLER_CONFIRMATION_FAIL'
export const SELLER_CONFIRMATION_RESET = 'SELLER_CONFIRMATION_RESET'

export const USER_FOLLOW_REQUEST = "USER_FOLLOW_REQUEST "
export const USER_FOLLOW_SUCCESS = "USER_FOLLOW_SUCCESS "
export const USER_FOLLOW_FAIL= "USER_FOLLOW_FAIL "

export const USER_BLOCK_REQUEST = 'USER_BLOCK_REQUEST'
export const USER_BLOCK_SUCCESS = 'USER_BLOCK_SUCCESS'
export const USER_BLOCK_FAIL = 'USER_BLOCK_FAIL'

export const USER_UNBLOCK_REQUEST = 'USER_UNBLOCK_REQUEST'
export const USER_UNBLOCK_SUCCESS = 'USER_UNBLOCK_SUCCESS'
export const USER_UNBLOCK_FAIL = 'USER_UNBLOCK_FAIL'


export const GET_MAIL_REQUEST = 'GET_MAIL_REQUEST'
export const GET_MAIL_SUCCESS = 'GET_MAIL_SUCCESS'
export const GET_MAIL_FAIL = 'GET_MAIL_FAIL'
 




