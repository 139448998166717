import { USER_DETAILS_FAIL, USER_LIST_RESET, USER_DETAILS_REQUEST, USER_DETAILS_RESET, USER_DETAILS_SUCCESS, USER_LIST_FAIL, USER_LIST_REQUEST, USER_LIST_SUCCESS, USER_LOGIN_FAIL, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGOUT, USER_REGISTER_FAIL, USER_REGISTER_REQUEST, USER_REGISTER_SUCCESS, USER_UPDATE_PROFILE_FAIL, USER_UPDATE_PROFILE_REQUEST, USER_UPDATE_PROFILE_RESET, USER_UPDATE_PROFILE_SUCCESS, USER_DELETE_REQUEST, USER_DELETE_SUCCESS, USER_DELETE_FAIL,
  USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAIL, USER_UPDATE_RESET, SELLER_REGISTER_REQUEST, SELLER_REGISTER_SUCCESS, SELLER_REGISTER_FAIL, SELLER_CONFIRMATION_REQUEST, SELLER_CONFIRMATION_SUCCESS, SELLER_CONFIRMATION_FAIL, USER_DETAILS_SELLER_REQUEST, USER_DETAILS_SELLER_SUCCESS, USER_DETAILS_SELLER_FAIL, USER_DETAILS_SELLER_RESET, USER_FOLLOW_REQUEST, USER_FOLLOW_SUCCESS, USER_FOLLOW_FAIL,USER_REGISTER_RESET,
GET_MAIL_REQUEST,
GET_MAIL_SUCCESS,
GET_MAIL_FAIL,
PROFILE_PICTURE_REQUEST,
PROFILE_PICTURE_SUCCESS,
PROFILE_PICTURE_FAIL,
PROFILE_PICTURE_RESET,
 

} from "../constants/userConstants"


export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true }
        case USER_LOGIN_SUCCESS:
            return { loading: false, userInfo: action.payload}
        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload}
        case USER_LOGOUT:
            return {}
        default: 
            return state

    }
}

export const userRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return { loading: true }
        case USER_REGISTER_SUCCESS:
            return { loading: false, userInfo: action.payload}
              
        case USER_REGISTER_FAIL:
            return { loading: false, error: action.payload}
        case USER_REGISTER_RESET:
            return { userInfo: {} }
        default: 
            return state

    }
}

export const userDetailsReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case USER_DETAILS_REQUEST:
            return { ...state , loading: true }
        case USER_DETAILS_SUCCESS:
            return { loading: false, user: action.payload}
        case USER_DETAILS_FAIL:
            return { loading: false, error: action.payload}
        case USER_DETAILS_RESET:
            return { user: {} }
        default: 
            return state

    }
}


export const profilePictureReducer = (state = { photos: {} }, action) => {
    switch (action.type) {
        case PROFILE_PICTURE_REQUEST:
            return { ...state , loading: true }
        case PROFILE_PICTURE_SUCCESS:
            return { loading: false, photos: action.payload}
        case PROFILE_PICTURE_FAIL:
            return { loading: false, error: action.payload}
        case PROFILE_PICTURE_RESET:
            return { photos: {} }
        default: 
            return state

    }
}


export const addProfilePicReducer = (state = {user:{}}, action) => {
   switch (action.type) {
        case USER_DETAILS_REQUEST:
            return { ...state , loading: true }
        case USER_DETAILS_SUCCESS:
            return { loading: false, user: action.payload}
        case USER_DETAILS_FAIL:
            return { loading: false, error: action.payload}
        case USER_DETAILS_RESET:
            return { user: {} }
        default: 
            return state
  }
}



export const userUpdateProfileReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case USER_UPDATE_PROFILE_REQUEST:
            return { loading: true }
        case USER_UPDATE_PROFILE_SUCCESS:
            return { loading: false, success: true, userInfo: action.payload}
        case USER_UPDATE_PROFILE_FAIL:
            return { loading: false, error: action.payload}
        case USER_UPDATE_PROFILE_RESET:
            return {}
        default: 
            return state

    }
}

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true }
    case USER_LIST_SUCCESS:
      return { loading: false, users: action.payload }
    case USER_LIST_FAIL:
      return { loading: false, error: action.payload }
    case USER_LIST_RESET:
      return { users: [] }
    default:
      return state
  }
}

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true }
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true }
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

// export const userUpdateReducer = (state = { user: {} }, action) => {
//   switch (action.type) {
//     case USER_UPDATE_PROFILE_REQUEST:
//       return { loading: true }
//     case USER_UPDATE_PROFILE_SUCCESS:
//       return { loading: false, success: true }
//     case USER_UPDATE_PROFILE_FAIL:
//       return { loading: false, error: action.payload }
//     case USER_UPDATE_PROFILE_RESET:
//       return {
//         user: {},
//       }
//     default:
//       return state
//   }
// }

     

      

export const userUpdateReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_RESET:
      return {
        user: {},
      };
    default:
      return state;
  }
};

export const sellerRegisterReducer = (state = {user: {} }, action) => {
  switch (action.type) {
    case SELLER_REGISTER_REQUEST:
      return {
        loading: true,
      }
    case SELLER_REGISTER_SUCCESS:
      return {
        loading: false,
        success: true ,
        user: action.payload,
      }
    case SELLER_REGISTER_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const sellerConfirmationReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case SELLER_CONFIRMATION_REQUEST:
      return {
        loading: true,
      }
    case SELLER_CONFIRMATION_SUCCESS:
      return {
        loading: false,
        user: action.payload,
      }
    case SELLER_CONFIRMATION_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const userDetailsSellerReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case USER_DETAILS_SELLER_REQUEST:
            return { ...state , loading: true }
        case USER_DETAILS_SELLER_SUCCESS:
            return { loading: false, user: action.payload}
        case USER_DETAILS_SELLER_FAIL:
            return { loading: false, error: action.payload}
        case USER_DETAILS_SELLER_RESET:
            return { user: {} }
        default: 
            return state

    }
}

export const userProfileUpdateReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true }
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true }
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload }
    case USER_UPDATE_PROFILE_RESET:
      return {
        user: {},
      }
    default:
      return state
  }
}


export const userFollowReducer = (state = {user:{}}, action) => {
  switch (action.type) {
    case USER_FOLLOW_REQUEST:
      return { loading: true}
    case USER_FOLLOW_SUCCESS:
      return { loading: false, success: true, user: action.payload}
    case USER_FOLLOW_FAIL:
      return { loading: false, error: action.payload }
    //  case USER_FOLLOW_RESET:
    //         return { user: {} }
    default:
      return state
  }
}

export const mailReducer = (state = {mail:{}}, action) => {
  switch(action.type) {

    case GET_MAIL_REQUEST:
      return { loading: true}

    case GET_MAIL_SUCCESS:
        return {
             loading: false, success: true, mail: action.payload
        }
   case GET_MAIL_FAIL:
      return { loading: false, error: action.payload }

    default:
    return state
  }
}




// export const blockUserContentReducer = (state = {user:{}}, action) => {
//   switch (action.type) {
//     case USER_BLOCK_REQUEST:
//       return { loading: true}
//     case USER_BLOCK_SUCCESS:
//       return { loading: false, success: true, user: action.payload}
//     case USER_BLOCK_FAIL:
//       return { loading: false, error: action.payload }
//     default:
//       return state
//   }
// }


// export const unBlockUserContentReducer = (state = {user:{}}, action) => {
//   switch (action.type) {
//     case USER_UNBLOCK_REQUEST:
//       return { loading: true}
//     case USER_UNBLOCK_SUCCESS:
//       return { loading: false, success: true, user: action.payload}
//     case USER_UNBLOCK_FAIL:
//       return { loading: false, error: action.payload }
//     default:
//       return state
//   }
// }




















